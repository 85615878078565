import { useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'rsuite/cjs/Slider/Slider'

import { AppMsgType } from 'atw-shared/utils'
import { removeBlob, displaySnackBar, getUserAvatarUrl } from 'client/services'
import { useUpdateUserAvatar } from 'client/api'
import { useEnvContext } from 'client/hooks'
import {
  AppHeader,
  AppButton,
  AppDivider,
  AppSegment,
  AppGroup,
} from 'client/ui-building-blocks'

import CropperSection from './CropperSection/cropper-section'
import cropperDefaults from './defaults'
import getCroppedImg from './helpers/getCroppedImg'
import base64ToFile from './helpers/base64ToFile'

import styles from './upload-avatar-form.module.scss'

export const IMAGE_INPUT_ID = 'upload-avatar-img-input'

type Props = {
  userId: string
}

let imageInputElem

const UploadAvatarForm = ({ userId }: Props) => {
  const [cropper, setCropper] = useState(cropperDefaults)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()
  const { awsBucketUrl } = useEnvContext()

  const { mutateAsync: updateUserAvatar } = useUpdateUserAvatar()

  useEffect(() => {
    imageInputElem = document.getElementById(IMAGE_INPUT_ID) as HTMLInputElement
  }, [])

  const onOpenClick = () => {
    document.getElementById(IMAGE_INPUT_ID)?.click()
  }

  const onResetClick = () =>
    setCropper({
      ...cropper,
      zoom: cropperDefaults.zoom,
      rotation: cropperDefaults.rotation,
      image: cropper.image,
    })

  const onCancelClick = () => {
    setCropper(cropperDefaults)
    imageInputElem.value = ''
  }

  const onChoose = (e: ChangeEvent<HTMLInputElement>) => {
    if (!/(\.jpg|\.jpeg|\.png)$/i.exec(imageInputElem.value)) {
      displaySnackBar({
        type: AppMsgType.Warning,
        text: t('settingsPage.avatar.messages.warning'),
      })
      return
    }
    setLoading(true)
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setTimeout(() => {
        setCropper({
          ...cropperDefaults,
          image: fileReader.result as string,
        })
        setLoading(false)
      }, 250)
    }
    const target = e.target as HTMLInputElement
    if (target.files[0]) {
      const file = target.files[0]
      fileReader.readAsDataURL(file)
    }
  }

  const onUploadClick = () => {
    setLoading(true)
    setTimeout(() => {
      const url = getUserAvatarUrl(awsBucketUrl, userId)
      const file = base64ToFile(cropper.image, 'avatar.png')
      const fileUrl = URL.createObjectURL(file)
      getCroppedImg(fileUrl, croppedAreaPixels, cropper.rotation)
        .then(croppedBase64 => {
          updateUserAvatar({ _id: userId, data: croppedBase64 })
            .then(res => {
              removeBlob(url)
              setCropper(cropperDefaults)
              setLoading(false)
              imageInputElem.value = ''
              if (res.msg) {
                displaySnackBar(res.msg)
              }
            })
            .catch(err => {
              handleError(err)
            })
        })
        .catch(err => {
          handleError(err)
        })
    }, 250)
  }

  const handleError = err => {
    setCropper(cropperDefaults)
    setLoading(false)
    imageInputElem.value = ''
    if (err.msg) {
      displaySnackBar(err.msg)
    }
  }

  const disabled = !cropper.image || isLoading

  const onZoomChange = value => setCropper({ ...cropper, zoom: Number(value) })
  const onRotationChange = value => setCropper({ ...cropper, rotation: Number(value) })

  const { minZoom, maxZoom } = cropperDefaults

  return (
    <AppSegment maxWidth="large" centered>
      <input
        id={IMAGE_INPUT_ID}
        type="file"
        accept=".png,.jpg,.jpeg"
        style={{ display: 'none' }}
        onChange={onChoose}
      />
      <div className={styles.uploadAvatarForm}>
        <div>
          <CropperSection
            cropper={cropper}
            setCropper={setCropper}
            setCroppedAreaPixels={setCroppedAreaPixels}
            userId={userId}
          />
        </div>
        <div>
          <AppButton
            variant="outlined"
            text={t('settingsPage.buttons.open')}
            disabled={isLoading}
            onClick={onOpenClick}
            fullWidth
          />
          <AppDivider size="large" />
          <AppHeader
            text={t('settingsPage.avatar.sliders.zoom')}
            size="small"
            disabled={disabled}
          />
          <AppDivider size="small" />
          <div className={styles.slider}>
            <Slider
              value={cropper.zoom}
              min={minZoom}
              max={maxZoom}
              step={0.001}
              tooltip={false}
              disabled={disabled}
              onChange={onZoomChange}
            />
          </div>
          <AppDivider />
          <AppHeader
            text={t('settingsPage.avatar.sliders.rotation')}
            size="small"
            disabled={disabled}
          />
          <AppDivider size="small" />
          <div className={styles.slider}>
            <Slider
              value={cropper.rotation}
              min={0}
              max={360}
              step={0.001}
              tooltip={false}
              disabled={disabled}
              onChange={onRotationChange}
            />
          </div>
          <AppDivider size="large" />
          <AppGroup fullWidth gapSize="small">
            <AppButton
              size="small"
              text={t('settingsPage.buttons.upload')}
              onClick={onUploadClick}
              disabled={disabled}
              loading={isLoading}
            />
            <AppButton
              variant="outlined"
              size="small"
              text={t('common.buttons.reset')}
              disabled={disabled}
              onClick={onResetClick}
            />
            <AppButton
              variant="outlined"
              size="small"
              text={t('common.buttons.cancel')}
              disabled={disabled}
              onClick={onCancelClick}
            />
          </AppGroup>
        </div>
      </div>
    </AppSegment>
  )
}

export default UploadAvatarForm
