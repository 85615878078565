import { useTranslation } from 'react-i18next'

import { AppDivider, AppParagraph } from 'client/ui-building-blocks'
import { useAppContext } from 'client/hooks'

const AuthInfo = () => {
  const { t } = useTranslation()

  const { authUser } = useAppContext()

  const text = authUser
    ? t('rootPage.topBar.loggedInAs') + ' ' + authUser.username
    : t('rootPage.topBar.loggedOut')

  return (
    <>
      <AppDivider size="xsmall" />
      <AppParagraph
        color="secondary"
        text={text}
        centered
        size="small"
        marginBottom="medium"
      />
    </>
  )
}

export default AuthInfo
