// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EUWruJ2rtVbP_3V1hrae{display:flex;flex-flow:row-reverse wrap;column-gap:125px;row-gap:75px;justify-content:space-around}.EUWruJ2rtVbP_3V1hrae>div{flex:1}.Hr0VLuCTYUwzJWmiUlwo{display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/client/pages/ProfilePage/profile-page.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CACA,gBAAA,CACA,YAAA,CACA,4BAAA,CAGF,0BACE,MAAA,CAGF,sBACE,YAAA,CACA,sBAAA","sourcesContent":[".grid {\n  display: flex;\n  flex-flow: row-reverse wrap;\n  column-gap: 125px;\n  row-gap: 75px;\n  justify-content: space-around;\n}\n\n.grid > div {\n  flex: 1;\n}\n\n.avatarContainer {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `EUWruJ2rtVbP_3V1hrae`,
	"avatarContainer": `Hr0VLuCTYUwzJWmiUlwo`
};
export default ___CSS_LOADER_EXPORT___;
