import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AppFormInput } from 'client/ui-building-blocks'
import { hasCredentialAllCharsValid } from 'atw-shared/validation'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from 'atw-shared/utils'

type Props = {
  isLoading: boolean
}

const PasswordInput = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name="password"
      rules={{
        required: t('common.validation.required'),
        minLength: {
          value: PASSWORD_MIN_LENGTH,
          message: t('common.validation.minLength', { min: PASSWORD_MIN_LENGTH }),
        },
        maxLength: {
          value: PASSWORD_MAX_LENGTH,
          message: t('common.validation.maxLength', { max: PASSWORD_MAX_LENGTH }),
        },
        validate: value =>
          hasCredentialAllCharsValid(value, true) || t('common.validation.invalidChars'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('common.password.password')}
            type="password"
            placeholder={`${PASSWORD_MIN_LENGTH} - ${PASSWORD_MAX_LENGTH}`}
            maxLength={PASSWORD_MAX_LENGTH}
            autoComplete="new-password"
            spellCheck={false}
            disabled={isLoading}
            error={String(errors?.password?.message || '')}
          />
        )
      }}
    />
  )
}

export default PasswordInput
