/* eslint-disable @typescript-eslint/no-extra-semi */
import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Lang } from 'atw-shared/utils'
import { DEFAULT_LANG, DEFAULT_THEME, Theme } from 'client/utils'
import { setLocalUserConfig, getLocalUserConfig } from 'client/services'
import { useAppContext } from 'client/hooks'

import {
  AppButton,
  AppForm,
  AppFormSelect,
  AppGroup,
  AppSegment,
} from 'client/ui-building-blocks'

type FormValues = { theme: Theme; lang: Lang }

const AppSettingsForm = () => {
  const { t, i18n } = useTranslation()
  const { authUser } = useAppContext()

  const localUserConfig = getLocalUserConfig(authUser._id.toString())

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      theme: (localUserConfig?.theme || DEFAULT_THEME) as Theme,
      lang: localUserConfig?.lang || DEFAULT_LANG,
    },
  })

  const onResetClick = () => {
    reset()
  }

  const themeOptions = useMemo(() => {
    return [
      {
        text: t('settingsPage.appSettings.fields.theme.options.light'),
        value: 'light',
      },
      {
        text: t('settingsPage.appSettings.fields.theme.options.dark'),
        value: 'dark',
      },
    ]
  }, [t])

  const langOptions = useMemo(() => {
    return [
      {
        text: t('settingsPage.appSettings.fields.lang.options.en'),
        value: Lang.EN,
      },
      {
        text: t('settingsPage.appSettings.fields.lang.options.pl'),
        value: Lang.PL,
      },
    ]
  }, [t])

  const onSubmit = handleSubmit(({ theme, lang }: FormValues) => {
    reset({
      theme,
      lang,
    })
    const html = document.querySelector('html')
    html.dataset.theme = theme
    i18n.changeLanguage(lang)
    setLocalUserConfig(authUser._id.toString(), lang, theme)
  })

  return (
    <AppSegment maxWidth="small" centered>
      <AppForm onSubmit={onSubmit}>
        <Controller
          control={control}
          name="lang"
          render={({ field: { value, onChange } }) => {
            return (
              <AppFormSelect
                label={t('settingsPage.appSettings.fields.lang.label')}
                value={value}
                options={langOptions}
                onChange={onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="theme"
          render={({ field: { value, onChange } }) => {
            return (
              <AppFormSelect
                label={t('settingsPage.appSettings.fields.theme.label')}
                value={value}
                options={themeOptions}
                onChange={onChange}
              />
            )
          }}
        />
        <AppGroup fullWidth alignment="verticalMobile">
          <AppButton text={t('common.buttons.save')} type="submit" disabled={!isDirty} />
          <AppButton
            variant="outlined"
            text={t('common.buttons.reset')}
            disabled={!isDirty}
            onClick={onResetClick}
          />
        </AppGroup>
      </AppForm>
    </AppSegment>
  )
}

export default AppSettingsForm
