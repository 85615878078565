import { QuestionType } from 'atw-shared/utils'

const EXAMPLES = {
  [QuestionType.SingleChoice]: {
    type: QuestionType.SingleChoice,
    categories: ['sport'],
    text: 'Ronaldo or Messi ?',
    canBeReanswered: false,
    numOfOptions: 2,
    numOfSelectableOptions: 1,
    rangeOfSelectableOptions: [1, 2],
    options: {
      1: 'CR9',
      2: 'Leo',
    },
  },
  [QuestionType.SingleChoiceYesOrNo]: {
    type: QuestionType.SingleChoiceYesOrNo,
    categories: ['sociology'],
    text: 'Are you married ?',
    canBeReanswered: true,
    numOfOptions: 2,
    numOfSelectableOptions: 1,
    rangeOfSelectableOptions: [1, 2],
    options: {
      1: 'YES',
      2: 'NO',
    },
  },
  [QuestionType.MultiChoiceExact]: {
    type: QuestionType.MultiChoiceExact,
    categories: ['psychology', 'sociology'],
    text: 'Choose your 3 most hated negative personality traits',
    canBeReanswered: false,
    numOfOptions: 12,
    numOfSelectableOptions: 3,
    rangeOfSelectableOptions: [-1, -1],
    options: {
      1: 'Unforgiving',
      2: 'Predatory',
      3: 'Aggresive',
      4: 'Vindictive',
      5: 'Narcissistic',
      6: 'Manipulative',
      7: 'Judgmental',
      8: 'Dishonest',
      9: 'Greedy',
      10: 'Needing to be right',
      11: 'Pessimistic',
      12: 'Egocentric',
    },
  },
  [QuestionType.MultiChoiceRange]: {
    type: QuestionType.MultiChoiceRange,
    categories: ['geography', 'traveling'],
    text: 'Which countries have you visited ?',
    canBeReanswered: true,
    numOfOptions: 10,
    numOfSelectableOptions: 1,
    rangeOfSelectableOptions: [1, 10],
    options: {
      1: 'Greece',
      2: 'Spain',
      3: 'USA',
      4: 'UK',
      5: 'Egypt',
      6: 'Germany',
      7: 'Italy',
      8: 'Portugal',
      9: 'Brazil',
      10: 'Argentina',
    },
  },
  [QuestionType.Rating]: {
    type: QuestionType.Rating,
    categories: ['technology'],
    text: 'Rate your computer skills on a scale of 1 to 5',
    canBeReanswered: true,
    numOfOptions: 5,
    numOfSelectableOptions: 1,
    rangeOfSelectableOptions: [1, 2],
    options: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
    },
  },
}

export default EXAMPLES
