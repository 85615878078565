import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import times from 'lodash/times'

import { ANSWER_INPUT_MAX_LENGTH } from 'atw-shared/utils'
import { AppDivider, AppFormInput } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
  defaultValue: number
}

const OptionInputs = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()

  const numOfOptions = watch('numOfOptions')

  return times(numOfOptions, (i: number) => {
    return (
      <Controller
        key={i}
        control={control}
        name={`options.${i + 1}`}
        rules={{
          required: t('common.validation.required'),
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <AppFormInput
                value={value}
                onChange={onChange}
                label={t('createQuestionPage.fields.answerNumber', { n: i + 1 })}
                maxLength={ANSWER_INPUT_MAX_LENGTH}
                disabled={isLoading}
                error={String(errors?.options?.[i + 1]?.message || '')}
              />
              <AppDivider size="small" />
            </>
          )
        }}
      />
    )
  })
}

export default OptionInputs
