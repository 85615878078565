import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IQuestion, QuestionType } from 'atw-shared/utils'
import { CreateQuestionFormValues, DEFAULT_THEME } from 'client/utils'
import { Question } from 'client/components'
import { useCreateQuestion } from 'client/api'
import { useAppContext, useModalDialogContext, usePage } from 'client/hooks'

import {
  displaySnackBar,
  getCreateQuestionInitialValues,
  isAccountFull,
  getAccountStatus,
  createQuestionFormValuesToBody,
  getAssetUrl,
  getLocalLastUserConfig,
} from 'client/services'

import {
  AppSegment,
  AppMessageBox,
  AppImage,
  AppDivider,
} from 'client/ui-building-blocks'

import NewQuestionForm from './NewQuestionForm/new-question-form'
import QuestionPreview from './QuestionPreview/question-preview'
import { fixScrollPosition } from 'client/helpers'

export type Screen = 'FORM_1' | 'FORM_2' | 'PREVIEW'

const CreateQuestionPage = () => {
  const { t } = useTranslation()

  const [activeScreen, setActiveScreen] = useState<Screen>('FORM_1')

  const { navigateTo } = usePage()
  const { showQuestionModal, toggleNoAccessModal } = useModalDialogContext()
  const { authUser } = useAppContext()

  const accountStatus = getAccountStatus(authUser)

  const {
    data: questionData,
    mutateAsync: createQuestion,
    isLoading: isCreatingQuestion,
  } = useCreateQuestion()

  const defaultValues = getCreateQuestionInitialValues(QuestionType.SingleChoice)

  const form = useForm<CreateQuestionFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues,
  })

  const { reset, getValues, watch } = form

  useEffect(() => {
    const subscription = watch(({ type }, { name }) => {
      if (name === 'type') {
        reset(getCreateQuestionInitialValues(type), {
          keepDefaultValues: true,
        })
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateQuestion = async () => {
    if (isAccountFull(accountStatus)) {
      const values = getValues()
      const payload = createQuestionFormValuesToBody(values)
      return createQuestion(payload as IQuestion)
        .then(data => {
          reset()
          navigateTo({ path: '/create', query: { _id: data.question._id.toString() } })
          if (data?.msg) {
            displaySnackBar(data.msg)
          }
          setActiveScreen('FORM_1')
          fixScrollPosition(false)
        })
        .catch(err => {
          if (err?.msg) {
            displaySnackBar(err.msg)
          }
          setActiveScreen('FORM_1')
          fixScrollPosition(false)
        })
    } else {
      toggleNoAccessModal(true)
    }
  }

  const currentTheme = getLocalLastUserConfig().theme || DEFAULT_THEME

  return (
    <FormProvider {...form}>
      <AppSegment
        headerProps={{
          text:
            activeScreen === 'PREVIEW'
              ? t('createQuestionPage.headerPreview')
              : t('createQuestionPage.header'),
          iconProps: {
            name: 'write',
          },
        }}
        maxWidth="medium"
        centered
      >
        {activeScreen !== 'PREVIEW' && (
          <>
            <AppMessageBox
              paragraphs={t('createQuestionPage.messages', { returnObjects: true })}
              imgNode={
                <AppImage
                  src={getAssetUrl(`survey-create-${currentTheme}.png`)}
                  width="90px"
                  height="90px"
                  alt="Create new question"
                />
              }
              size="small"
            />
            <AppDivider size="large" />
          </>
        )}
        {activeScreen === 'PREVIEW' ? (
          <QuestionPreview
            isCreatingQuestion={isCreatingQuestion}
            setActiveScreen={setActiveScreen}
            onSubmit={handleCreateQuestion}
          />
        ) : (
          <NewQuestionForm
            isCreatingQuestion={isCreatingQuestion}
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
          />
        )}
      </AppSegment>
      {showQuestionModal && (
        <Question questionId={questionData?.question._id.toString()} isInModal />
      )}
    </FormProvider>
  )
}

export default CreateQuestionPage
