import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Question } from 'client/components'
import { displaySnackBar } from 'client/services'
import { useGetRandomQuestionsIds } from 'client/api'
import { useAppContext } from 'client/hooks'

import {
  AppParagraph,
  AppSegment,
  AppButton,
  AppDivider,
} from 'client/ui-building-blocks'

const RandomQuestionPage = () => {
  const { t } = useTranslation()

  const { randomQuestionIndex, setRandomQuestionIndex } = useAppContext()

  const {
    data: randomIdsData,
    refetch: fetchRandomIds,
    isLoading: isLoadingRandomIds,
    isFetching: isFetchingRandomIds,
  } = useGetRandomQuestionsIds({
    onSuccess: data => {
      if (data.data.length) {
        setRandomQuestionIndex(0)
      }
    },
    onError: err => {
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  useEffect(() => {
    setRandomQuestionIndex(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      randomQuestionIndex === null ||
      randomQuestionIndex > randomIdsData?.data.length - 1
    ) {
      fetchRandomIds()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomQuestionIndex])

  const questionId =
    randomQuestionIndex !== null ? randomIdsData?.data[randomQuestionIndex] : null

  const loadingIds = isLoadingRandomIds || isFetchingRandomIds

  return (
    <AppSegment
      maxWidth="large"
      centered
      headerProps={{
        text: t('randomQuestionPage.header'),
        iconProps: {
          name: 'random',
        },
        marginBottom: 'xsmall',
      }}
    >
      {(loadingIds || questionId) && <Question questionId={questionId} />}
      {randomIdsData && randomIdsData.data.length === 0 && !loadingIds && (
        <>
          <AppDivider size="small" />
          <AppParagraph
            text={t('common.info.nothingToDisplay')}
            centered
            marginBottom="xsmall"
          />
          <AppButton
            text={t('common.buttons.refresh')}
            onClick={fetchRandomIds}
            position="center"
            loading={loadingIds}
          />
        </>
      )}
    </AppSegment>
  )
}

export default RandomQuestionPage
