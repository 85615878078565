import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AppHeader, AppSectionLayout, AppParagraph } from 'client/ui-building-blocks'
import { formatNumber } from 'client/helpers'

type Props = {
  stats: {
    count: {
      users: number
      questions: number
      answers: number
    }
  }
}

const Stats = ({ stats }: Props) => {
  const { t } = useTranslation()

  return useMemo(() => {
    const usersCount = formatNumber(stats.count.users)
    const questionsCount = formatNumber(stats.count.questions)
    const answersCount = formatNumber(stats.count.answers)

    const usersLabel = t('homePage.stats.user', { count: stats.count.users })
    const questionsLabel = t('homePage.stats.question', { count: stats.count.questions })
    const answersLabel = t('homePage.stats.answer', { count: stats.count.answers })

    return (
      <div data-testid="home-stats">
        <AppSectionLayout autoWrap padded>
          <div>
            <AppHeader text={usersCount} size="xxlarge" centered />
            <AppParagraph text={usersLabel} centered />
          </div>
          <div>
            <AppHeader text={questionsCount} size="xxlarge" centered />
            <AppParagraph text={questionsLabel} centered />
          </div>
          <div>
            <AppHeader text={answersCount} size="xxlarge" centered />
            <AppParagraph text={answersLabel} centered />
          </div>
        </AppSectionLayout>
      </div>
    )
  }, [stats, t])
}

Stats.defaultProps = {
  stats: {
    count: {
      users: 0,
      questions: 0,
      answers: 0,
    },
  },
}

export default Stats
