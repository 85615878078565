import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { AppSegment } from 'client/ui-building-blocks'
import { Question } from 'client/components'

const QuestionPage = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { _id } = qs.parse(search.substring(1))

  return (
    <AppSegment
      maxWidth="large"
      centered
      headerProps={{
        text: t('question.header'),
        iconProps: {
          name: 'question',
        },
        marginBottom: 'xsmall',
      }}
    >
      <Question questionId={_id.toString()} />
    </AppSegment>
  )
}

export default QuestionPage
