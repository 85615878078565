import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QUESTION_SEARCH_MAX_LENGTH } from 'atw-shared/utils'
import { BrowseQuestionsFormValues } from 'client/utils'
import { useGetQuestionCategories } from 'client/api'
import { useAppContext } from 'client/hooks'

import {
  AppButton,
  AppGroup,
  AppDivider,
  AppFormAutoComplete,
  AppFormInput,
  AppChip,
  AppForm,
} from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
  onSubmitClick: (data: BrowseQuestionsFormValues) => void
}

const SearchForm = ({ isLoading = false, onSubmitClick }: Props) => {
  const { t, i18n } = useTranslation()

  const { setBrowseQuestionsPageState, browseQuestionsPageState, authUser } =
    useAppContext()

  const { user } = browseQuestionsPageState

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    setValue,
    setError,
  } = useFormContext<BrowseQuestionsFormValues>()

  const { isFetching: isFetchingCategories, data: categories } = useGetQuestionCategories(
    {
      onError: err => {
        if (err?.msg) {
          setError('categories', {
            message: t(`apiMsgs.${err.msg.code}`),
          })
        }
      },
    }
  )

  const onSearchClearClick = () => {
    setValue('search', '', { shouldDirty: true })
  }

  const onClearClick = () => {
    setValue('categories', [], { shouldDirty: true })
    setValue('search', '', { shouldDirty: true })
  }

  const onUserFilterClear = () => {
    if (isLoading) return
    setBrowseQuestionsPageState(state => ({ ...state, user: null, pageNo: 1 }))
  }

  return (
    <AppForm
      onSubmit={handleSubmit(data => {
        return new Promise(resolve => {
          if (!isLoading) {
            onSubmitClick(data)
          } else {
            resolve(true)
          }
        })
      })}
    >
      {user && user._id.toString() !== authUser._id.toString() && (
        <>
          <AppChip
            variant="outlined"
            text={t('browseQuestionsPage.buttons.createdBy', {
              username: user.username,
            })}
            iconName="close"
            onIconClick={onUserFilterClear}
            centered
          />
          <AppDivider />
        </>
      )}
      <Controller
        control={control}
        name="categories"
        render={({ field: { value, onChange } }) => {
          return (
            <AppFormAutoComplete
              label={t('browseQuestionsPage.fields.categories.label')}
              placeholder={t('browseQuestionsPage.fields.categories.placeholder')}
              value={value}
              options={categories ? categories[i18n.language.toLowerCase()] : []}
              disabled={isFetchingCategories}
              onChange={(e, data) => {
                onChange({ target: { value: data.value } })
              }}
              multiple
            />
          )
        }}
      />
      <Controller
        control={control}
        name="search"
        render={({ field: { value, onChange } }) => {
          return (
            <AppFormInput
              value={value}
              onChange={onChange}
              label={t('browseQuestionsPage.fields.search.label')}
              placeholder={t('browseQuestionsPage.fields.search.placeholder')}
              maxLength={QUESTION_SEARCH_MAX_LENGTH}
              onClearText={onSearchClearClick}
            />
          )
        }}
      />
      <AppGroup fullWidth="mobile" alignment="verticalMobile" centered>
        <AppButton
          id="search-form-submit-btn"
          text={t('common.buttons.search')}
          loading={isSubmitting}
        />
        <AppButton
          variant="outlined"
          text={t('common.buttons.clear')}
          type="reset"
          onClick={onClearClick}
          disabled={!isDirty || isLoading}
        />
      </AppGroup>
    </AppForm>
  )
}

export default SearchForm
