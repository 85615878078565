import { useTranslation } from 'react-i18next'

import { Filter, IUser } from 'atw-shared/utils'
import { formatNumber } from 'client/helpers'
import { getAccountStatus, isAccountFull } from 'client/services'
import { useAppContext, usePage } from 'client/hooks'

import {
  AppButton,
  AppDivider,
  AppHeader,
  AppParagraph,
  AppSectionLayout,
} from 'client/ui-building-blocks'

type Props = {
  data: {
    count: {
      questions: number
      answers: number
    }
    user: IUser
  }
}

const Stats = ({ data }: Props) => {
  const { t } = useTranslation()

  const { setBrowseQuestionsPageState } = useAppContext()
  const { navigateTo } = usePage()

  const accountStatus = getAccountStatus(data?.user)

  const enableGoToQuestionsButton = isAccountFull(accountStatus)

  const onUserQuestionsClick = () => {
    setBrowseQuestionsPageState(state => ({
      ...state,
      tabKey: Filter.Created,
      user: data?.user,
      pageNo: 1,
    }))
    navigateTo({ path: '/browse' })
  }

  return (
    <>
      <AppSectionLayout>
        <div>
          <AppHeader
            text={formatNumber(data?.count?.questions)}
            size="xxlarge"
            centered
          />
          <AppParagraph
            text={t('profilePage.stats.question', { count: data?.count.questions })}
            centered
          />
        </div>
        <div>
          <AppHeader text={formatNumber(data?.count?.answers)} size="xxlarge" centered />
          <AppParagraph
            text={t('profilePage.stats.answer', { count: data?.count.answers })}
            centered
          />
        </div>
      </AppSectionLayout>
      <AppDivider size="small" />
      <AppButton
        text={t('profilePage.buttons.goToQuestions')}
        disabled={!enableGoToQuestionsButton}
        onClick={onUserQuestionsClick}
        position="center"
      />
      <AppDivider size="xlarge" />
    </>
  )
}

Stats.defaultProps = {
  data: {
    count: {
      questions: 0,
      answers: 0,
    },
  },
}

export default Stats
