import { useTranslation } from 'react-i18next'

import { useGetActivationLink } from 'client/api'
import { displaySnackBar, getAccountStatus } from 'client/services'
import { isAccountConfirmed } from 'client/services'
import { useAppContext } from 'client/hooks'

import {
  AppMessageBox,
  AppDivider,
  AppButton,
  AppSegment,
} from 'client/ui-building-blocks'

const AccountActivationMsg = () => {
  const { t } = useTranslation()
  const { authUser } = useAppContext()

  const accountStatus = getAccountStatus(authUser)

  const { refetch: getActivationLink, isFetching } = useGetActivationLink({
    onSuccess: data => {
      if (data?.msg) {
        displaySnackBar(data.msg)
      }
    },
    onError: err => {
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  const onClick = () => {
    getActivationLink()
  }

  if (!authUser || isAccountConfirmed(accountStatus)) return null

  return (
    <AppSegment maxWidth="small" centered>
      <AppMessageBox
        paragraphs={[t('rootPage.activationBox.description')]}
        buttonNode={
          <AppButton
            text={t('rootPage.activationBox.buttons.send')}
            loading={isFetching}
            onClick={onClick}
            position="center"
          />
        }
      />
      <AppDivider size="large" />
    </AppSegment>
  )
}

export default AccountActivationMsg
