/* eslint-disable @typescript-eslint/no-extra-semi */
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QuestionType } from 'atw-shared/utils'
import { AppFormSelect } from 'client/ui-building-blocks'

const TypeSelect = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const options = useMemo(() => {
    return [
      {
        value: QuestionType.SingleChoice,
        text: t('createQuestionPage.fields.type.options.singleChoice'),
      },
      {
        value: QuestionType.SingleChoiceYesOrNo,
        text: t('createQuestionPage.fields.type.options.singleChoiceYesOrNo'),
      },
      {
        value: QuestionType.MultiChoiceExact,
        text: t('createQuestionPage.fields.type.options.multiChoiceExact'),
      },
      {
        value: QuestionType.MultiChoiceRange,
        text: t('createQuestionPage.fields.type.options.multiChoiceRange'),
      },
      {
        value: QuestionType.Rating,
        text: t('createQuestionPage.fields.type.options.rating'),
      },
    ]
  }, [t])

  return (
    <Controller
      control={control}
      name="type"
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormSelect
            label={t('createQuestionPage.fields.type.label')}
            value={value}
            options={options}
            onChange={onChange}
          />
        )
      }}
    />
  )
}

export default TypeSelect
