import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormName, RecoverPasswordFormValues } from 'client/utils'
import { useAppContext } from 'client/hooks'
import { AppButton, AppDivider, AppForm, AppGroup } from 'client/ui-building-blocks'
import { useGetRecoveryLink, useRecoverPassword } from 'client/api'

import {
  parseApiErrors,
  startRecoverEmailSocket,
  killRecoverEmailSocket,
  displaySnackBar,
} from 'client/services'

import EmailInput from './fields/email-input'
import NewPasswordInput from './fields/new-password-input'

type Props = {
  setActiveForm: (name: FormName) => void
}

const RecoverPasswordForm = ({ setActiveForm }: Props) => {
  const [apiErrors, setApiErrors] = useState(null)

  const { t } = useTranslation()
  const { recoverPassToken, setRecoverPassToken } = useAppContext()

  const {
    mutateAsync: getRecoveryLink,
    isLoading: isGettingRecoveryLink,
    isSuccess: getRecoveryLinkSuccess,
  } = useGetRecoveryLink()

  const { mutateAsync: recoverPassword, isLoading: isRecoveringPassword } =
    useRecoverPassword()

  const handleGetRecoveryLink = (values: RecoverPasswordFormValues) => {
    return getRecoveryLink(values).then(data => {
      startRecoverEmailSocket(values.email, setRecoverPassToken)
      if (data?.msg) {
        displaySnackBar(data.msg)
      }
    })
  }

  const handleRecoverPassword = (formValues: RecoverPasswordFormValues) => {
    return recoverPassword({ formValues, token: recoverPassToken }).then(data => {
      setRecoverPassToken('')
      setActiveForm(FormName.AuthenticationForm)
      if (data?.msg) {
        displaySnackBar(data.msg)
      }
    })
  }

  const form = useForm<RecoverPasswordFormValues, undefined, RecoverPasswordFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
      newPassword: '',
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form

  const onClearClick = () => {
    reset()
    setApiErrors(null)
  }

  const onCloseClick = () => {
    killRecoverEmailSocket()
    setRecoverPassToken('')
    setActiveForm(FormName.AuthenticationForm)
    window.scrollTo(0, 0)
  }

  const handleApiError = err => {
    if (err?.valErr) {
      setApiErrors(parseApiErrors(err?.valErr))
    }
  }

  const isLoading = isGettingRecoveryLink || isRecoveringPassword

  return (
    <>
      <FormProvider {...form}>
        <AppForm
          onSubmit={handleSubmit(data => {
            return !recoverPassToken
              ? handleGetRecoveryLink(data).catch(handleApiError)
              : handleRecoverPassword(data).catch(handleApiError)
          })}
        >
          <EmailInput
            getRecoveryLinkSuccess={getRecoveryLinkSuccess}
            apiErrors={apiErrors}
            isLoading={isLoading}
          />
          <NewPasswordInput
            isLoading={isLoading}
            getRecoveryLinkSuccess={getRecoveryLinkSuccess}
          />
          <AppGroup fullWidth alignment="verticalMobile">
            <AppButton
              text={
                recoverPassToken ? t('common.buttons.save') : t('common.buttons.submit')
              }
              loading={isLoading}
              disabled={!isDirty || (!recoverPassToken && getRecoveryLinkSuccess)}
              type="submit"
            />
            <AppButton
              variant="outlined"
              text={t('common.buttons.clear')}
              onClick={onClearClick}
              disabled={
                isLoading || !isDirty || (!recoverPassToken && getRecoveryLinkSuccess)
              }
              type="reset"
            />
          </AppGroup>
        </AppForm>
      </FormProvider>
      <AppDivider border size="large" />
      <AppButton
        variant="outlined"
        text={t('common.buttons.back')}
        disabled={isLoading}
        onClick={onCloseClick}
        position="center"
        size="small"
        iconName="prev"
      />
    </>
  )
}

export default RecoverPasswordForm
