import Cropper from 'react-easy-crop'
import { Area } from 'react-easy-crop/types'

import { UserAvatar } from 'client/components'
import { AVATAR_SIZE } from 'client/utils'

import { TCropper } from './../types'

type Props = {
  cropper: TCropper
  setCropper: (options: TCropper) => void
  setCroppedAreaPixels: (area: Area) => void
  userId: string
}

const CropperSection = ({ cropper, setCropper, setCroppedAreaPixels, userId }: Props) => {
  const onCropChange = ({ x, y }) => setCropper({ ...cropper, crop: { x, y } })

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  return (
    <>
      {cropper.image ? (
        <Cropper
          style={{
            containerStyle: {
              position: 'relative',
              margin: '0 auto',
              width: `${AVATAR_SIZE}px`,
              height: `${AVATAR_SIZE}px`,
              borderRadius: '50%',
              boxShadow: 'black 0 0 8px 0',
            },
          }}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          {...cropper}
        />
      ) : (
        <UserAvatar userId={userId} size="xlarge" rounded />
      )}
    </>
  )
}

export default CropperSection
