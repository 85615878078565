import { useTranslation } from 'react-i18next'

import { STRIPE_HOME_PAGE } from 'client/utils'
import { getAssetUrl } from 'client/services'
import { useAppContext, useEnvContext } from 'client/hooks'
import { AppButton, AppDivider, AppImage, AppSegment } from 'client/ui-building-blocks'

const Payment = () => {
  const { t } = useTranslation()
  const { fullAccountPaymentLink } = useEnvContext()
  const { authUser } = useAppContext()

  const onMakePaymentBtnClick = () => {
    window.open(fullAccountPaymentLink, '_blank')
  }

  const onTransactionDetailsBtnClick = () => {
    window.open(authUser.config.payment.data.object.receipt_url, '_blank')
  }

  return (
    <AppSegment centered>
      <a rel="noreferrer" href={STRIPE_HOME_PAGE} target="_blank">
        <AppImage
          src={getAssetUrl('stripe-logo.png')}
          width="350px"
          height="85px"
          centered
        />
      </a>
      <AppDivider size="large" />
      <AppButton
        variant="outlined"
        text={
          authUser.config.payment
            ? t('settingsPage.payment.buttons.seeTransactionDetails')
            : t('settingsPage.payment.buttons.makePayment')
        }
        iconName={authUser.config.payment ? 'checked' : undefined}
        onClick={
          authUser.config.payment ? onTransactionDetailsBtnClick : onMakePaymentBtnClick
        }
        position="center"
      />
    </AppSegment>
  )
}

export default Payment
