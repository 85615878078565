import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AppFormCheckBox } from 'client/ui-building-blocks'

const CanBeReansweredCheckbox = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="canBeReanswered"
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormCheckBox
            currentValues={value ? [0] : []}
            options={[
              { value: 0, label: t('createQuestionPage.fields.canBeReanswered') },
            ]}
            onChange={values => {
              onChange({ target: { value: values.includes(0) } })
            }}
          />
        )
      }}
    />
  )
}

export default CanBeReansweredCheckbox
