import { useMemo, useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AccountStatus } from 'atw-shared/utils'
import { AppSegment, AppFluidMenu, IconName } from 'client/ui-building-blocks'
import { getAccountStatus, isAccountConfirmed } from 'client/services'
import { useAppContext } from 'client/hooks'

import AppSettingsForm from './AppSettingsForm/app-settings-form'
import UpdateUserDetailsForm from './UpdateUserDetailsForm/update-user-details-form'
import DeactivateAccounForm from './DeactivateAccountForm/deactivate-account-form'
import UpdatePasswordForm from './UpdatePasswordForm/update-password-form'
import UploadAvatarForm from './UploadAvatarForm/upload-avatar-form'
import Payment from './Payment/payment'

const SettingsPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const {
    authUser,
    settingsPageState: { tabNo },
    setSettingsPageState,
  } = useAppContext()

  const accountStatus = getAccountStatus(authUser)

  const onTabSwitch = useCallback(
    (tabNo: number) => {
      return () => {
        if (!queryClient.isFetching() && !queryClient.isMutating()) {
          setSettingsPageState({ tabNo })
          return true
        }
        return false
      }
    },
    [queryClient, setSettingsPageState]
  )

  const accountConfirmed = isAccountConfirmed(accountStatus)

  const menuItems = useMemo(() => {
    if (!authUser) return []
    return [
      {
        label: t('settingsPage.tabs.appSettings'),
        icon: 'settings',
        onClick: onTabSwitch(1),
      },
      {
        label: t('settingsPage.tabs.personalDetails'),
        icon: 'user',
        onClick: onTabSwitch(2),
      },
      {
        label: t('settingsPage.tabs.password'),
        icon: 'password',
        onClick: onTabSwitch(3),
      },
      {
        label: t('settingsPage.tabs.profilePicture'),
        icon: 'avatar',
        onClick: onTabSwitch(4),
        disabled: !accountConfirmed,
      },
      {
        label: t('settingsPage.tabs.payment'),
        icon: 'payment',
        onClick: onTabSwitch(5),
        disabled: !accountConfirmed,
        hide: authUser.config.payment?.type === AccountStatus.FREE,
      },
      {
        label: t('settingsPage.tabs.deactivation'),
        icon: 'deactivation',
        onClick: onTabSwitch(6),
      },
    ] as {
      label: string
      icon: IconName
      onClick: () => boolean
      disabled?: boolean
      hide?: boolean
    }[]
  }, [accountConfirmed, authUser, onTabSwitch, t])

  if (!authUser) return null

  return (
    <AppSegment
      headerProps={{
        text: t('settingsPage.header'),
        iconProps: {
          name: 'settings',
        },
      }}
      centered
    >
      <AppFluidMenu menuItems={menuItems} defaultItemIndex={tabNo - 1}>
        <AppSettingsForm />
        <UpdateUserDetailsForm />
        <UpdatePasswordForm />
        <UploadAvatarForm userId={authUser._id.toString()} />
        <Payment />
        <DeactivateAccounForm />
      </AppFluidMenu>
    </AppSegment>
  )
}

export default SettingsPage
