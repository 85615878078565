import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QUESTION_INPUT_MAX_LENGTH } from 'atw-shared/utils'
import { AppFormInput } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
}

const TextInput = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name="text"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('createQuestionPage.fields.yourQuestion')}
            disabled={isLoading}
            maxLength={QUESTION_INPUT_MAX_LENGTH}
            error={String(errors.text?.message || '')}
          />
        )
      }}
    />
  )
}

export default TextInput
