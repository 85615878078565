import { ReactNode } from 'react'

import { AppDivider } from 'client/ui-building-blocks'

import styles from './page-container.module.scss'

type Props = {
  children: ReactNode
}

const PageContainer = ({ children }: Props) => {
  return (
    <div data-testid="page-container" className={styles.pageContainer}>
      {children}
      <AppDivider size="xlarge" />
    </div>
  )
}

export default PageContainer
