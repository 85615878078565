export default (base64: string, filename: string) => {
  const arr = base64.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[Number(n)] = bstr.charCodeAt(Number(n))
  }

  return new File([u8arr], filename, { type: mime })
}
