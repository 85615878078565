import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PASSWORD_MAX_LENGTH } from 'atw-shared/utils'
import { AppFormInput } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
}

const CurrentPasswordInput = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name="currentPassword"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('common.password.currentPassword')}
            type="password"
            maxLength={PASSWORD_MAX_LENGTH}
            autoComplete="new-password"
            spellCheck={false}
            disabled={isLoading}
            error={String(errors?.currentPassword?.message || '')}
          />
        )
      }}
    />
  )
}

export default CurrentPasswordInput
