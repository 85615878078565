// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ba41K5wzNMzchjYtDnmM{display:flex;flex-flow:column nowrap}.ba41K5wzNMzchjYtDnmM div.Z_3xD6KAvf9NCuVF6aET{flex:1;min-height:100vh}`, "",{"version":3,"sources":["webpack://./src/client/pages/RootPage/root-page.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,uBAAA,CAEA,+CACE,MAAA,CACA,gBAAA","sourcesContent":[".rootPage {\n  display: flex;\n  flex-flow: column nowrap;\n\n  div.mainContent {\n    flex: 1;\n    min-height: 100vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootPage": `ba41K5wzNMzchjYtDnmM`,
	"mainContent": `Z_3xD6KAvf9NCuVF6aET`
};
export default ___CSS_LOADER_EXPORT___;
