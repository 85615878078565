import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'

import { Filter } from 'atw-shared/utils'
import { DEFAULT_THEME, FormName, RegisterFormValues } from 'client/utils'
import { getLocalLastUserConfig, parseApiErrors, startUserSocket } from 'client/services'
import { useEnvContext, useAppContext, usePage } from 'client/hooks'
import { useRegisterUser } from 'client/api'

import {
  AppButton,
  AppDivider,
  AppForm,
  AppGroup,
  AppParagraph,
} from 'client/ui-building-blocks'

import {
  NewEmailInput,
  NewUsernameInput,
  DateOfBirthPicker,
  CountryAutocomplete,
  SexCheckbox,
} from 'client/components'

import PasswordInput from './fields/password-input'

import styles from './register-form.module.scss'

type Props = {
  setActiveForm: (name: FormName) => void
}

const RegisterForm = ({ setActiveForm }: Props) => {
  const { t, i18n } = useTranslation()

  const [apiErrors, setApiErrors] = useState(null)
  const [recaptchaSelected, setRecaptchaSelected] = useState<boolean>(false)

  const {
    setBrowseQuestionsPageState,
    setAuthUser,
    setRedirectQuestionId,
    redirectQuestionId,
  } = useAppContext()

  const { recaptchaSiteKey } = useEnvContext()
  const { navigateTo } = usePage()

  const form = useForm<RegisterFormValues, undefined, RegisterFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
      username: '',
      password: '',
      dateOfBirth: null,
      country: '',
      sex: '',
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = form

  const { mutateAsync: register, isLoading: isRegistering } = useRegisterUser({
    onSuccess: data => {
      if (data.user.config.confirmed) {
        setBrowseQuestionsPageState(state => ({ ...state, tabKey: Filter.NotAnswered }))
      }

      setAuthUser(data.user)
      startUserSocket(data.user._id.toString())

      if (redirectQuestionId) {
        navigateTo({ path: '/question', query: { _id: redirectQuestionId } })
        setRedirectQuestionId(null)
      } else {
        navigateTo({ path: '/profile?_id=' + data.user._id })
      }
    },
  })

  const onClearClick = () => {
    reset()
    setApiErrors(null)
  }

  const onLoginClick = () => {
    setActiveForm(FormName.AuthenticationForm)
    window.scrollTo(0, 0)
  }

  const onRecaptchaChange = () => {
    setRecaptchaSelected(true)
  }

  const onSubmit = handleSubmit(data => {
    return register({
      ...data,
      dateOfBirth: new Date(data.dateOfBirth).toISOString().split('T')[0],
    }).catch(err => {
      if (err?.valErr) {
        setApiErrors(parseApiErrors(err.valErr))
      }
    })
  })

  const currentTheme = getLocalLastUserConfig().theme || DEFAULT_THEME

  return (
    <>
      <FormProvider {...form}>
        <AppForm onSubmit={onSubmit}>
          <NewEmailInput isLoading={isRegistering} apiErrors={apiErrors} />
          <NewUsernameInput isLoading={isRegistering} apiErrors={apiErrors} />
          <PasswordInput isLoading={isRegistering} />
          <DateOfBirthPicker isLoading={isRegistering} />
          <CountryAutocomplete isLoading={isRegistering} />
          <SexCheckbox isLoading={isRegistering} />
          <AppDivider size="xxsmall" />
          <div className={styles.recaptchaContainer}>
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              theme={currentTheme}
              hl={i18n.language}
              onChange={onRecaptchaChange}
              style={{
                transform: 'scale(1.5) !important',
                transformOrigin: '0 0 !important',
              }}
            />
          </div>
          <AppDivider size="small" />
          <AppGroup fullWidth alignment="verticalMobile">
            <AppButton
              text={t('common.buttons.submit')}
              type="submit"
              loading={isRegistering}
              disabled={!recaptchaSelected || !isDirty}
            />
            <AppButton
              variant="outlined"
              type="reset"
              text={t('common.buttons.clear')}
              onClick={onClearClick}
              disabled={isRegistering || !isDirty}
            />
          </AppGroup>
        </AppForm>
      </FormProvider>
      <AppDivider border size="large" />
      <AppParagraph
        text={t('authPage.register.alreadyHaveAnAccount')}
        centered
        size="small"
        marginBottom="xsmall"
      />
      <AppButton
        variant="outlined"
        text={t('authPage.register.login')}
        onClick={onLoginClick}
        position="center"
        size="small"
      />
    </>
  )
}

export default RegisterForm
