import { useTranslation } from 'react-i18next'

import { IUser } from 'atw-shared/utils'
import { COUNTRY_FLAG_SIZE } from 'client/utils'
import { useGetCountries } from 'client/api'
import { useAppContext } from 'client/hooks'

import {
  AppDivider,
  AppHeader,
  AppImage,
  AppParagraph,
  AppPopup,
} from 'client/ui-building-blocks'

import {
  humanizeFromToNow,
  isAccountConfirmed,
  getCountryFlagUrl,
  getAccountStatus,
} from 'client/services'

type Props = {
  data: {
    count: {
      questions: number
      answers: number
    }
    user: IUser
  }
  isLoading: boolean
}

const Badge = ({ data, isLoading }: Props) => {
  const { t, i18n } = useTranslation()

  const { data: countries } = useGetCountries({})
  const { authUser } = useAppContext()

  const accountStatus = getAccountStatus(authUser)

  const countryLabel =
    data &&
    countries?.[i18n.language.toLowerCase()].find(
      item => item.value === data.user.country
    ).text

  const src = getCountryFlagUrl(data?.user)

  const isProfileUserAuthUser = authUser._id.toString() === data?.user._id.toString()

  const displayEmailConfirmedIcon =
    isProfileUserAuthUser && isAccountConfirmed(accountStatus)

  const timePassed = humanizeFromToNow(data?.user.config.registeredAt, i18n.language)

  return (
    <>
      <AppHeader
        text={data?.user.username}
        size="xlarge"
        iconProps={
          displayEmailConfirmedIcon
            ? {
                name: 'checked',
                color: 'green',
                popupText: t('profilePage.badge.emailStatus.confirmed'),
                position: 'right',
                size: 'large',
              }
            : null
        }
        loading={isLoading}
        marginBottom="xsmall"
      />
      <AppPopup text={countryLabel} position="right center">
        <AppImage src={src} height={COUNTRY_FLAG_SIZE + 'px'} />
      </AppPopup>
      <AppDivider size="xsmall" />
      <AppParagraph
        text={timePassed ? t('profilePage.badge.userSinceInfo', { timePassed }) : ''}
        size="small"
        color="secondary"
        loading={isLoading}
      />
    </>
  )
}

export default Badge
