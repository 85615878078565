import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { Filter, ApiUrlPath, READ_TOP_QUESTIONS_MAX } from 'atw-shared/utils'
import { AppButton, AppDivider, AppGroup, AppImage } from 'client/ui-building-blocks'
import { QuestionsList } from 'client/components'
import { displaySnackBar, getAssetUrl } from 'client/services'
import { useGetStats, useGetTopQuestions } from 'client/api'
import { useModalDialogContext, useAppContext, usePage } from 'client/hooks'

import Stats from './Stats/stats'
import UsersList from './UsersList/users-list'

import styles from './home-page.module.scss'

const API_PATH = process.env.API_PATH

const HomePage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { setBrowseQuestionsPageState, authUser } = useAppContext()
  const { showQuestionModal } = useModalDialogContext()
  const { navigateTo } = usePage()

  const stats = useGetStats()

  const topQuestions = useGetTopQuestions({
    showQuestionModal,
    onError: err => {
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  useEffect(() => {
    if (showQuestionModal) {
      queryClient.cancelQueries([API_PATH + ApiUrlPath.GetQuestions, Filter.Top])
    }
  }, [showQuestionModal, queryClient])

  return useMemo(() => {
    const onLatestSurveysClick = () => {
      setBrowseQuestionsPageState(state => ({
        ...state,
        tabKey: authUser ? Filter.NotAnswered : Filter.All,
        user: authUser || null,
        pageNo: 1,
      }))
      navigateTo({ path: '/browse' })
    }

    const onCreateNewQuestionClick = () => {
      navigateTo({ path: '/create' })
    }

    return (
      <>
        <div className={styles.topSection}>
          <div>
            <AppImage src={getAssetUrl('logo.png')} width="175px" alt="logo" />
          </div>
          <div>
            <Stats stats={stats.data} />
            <AppDivider size="small" />
            <AppGroup
              centered
              gapSize="small"
              alignment="verticalMobile"
              fullWidth="mobile"
            >
              <AppButton
                text={t('homePage.latestSurveys.button')}
                onClick={onLatestSurveysClick}
                position="center"
                iconName="waiting"
              />
              <AppButton
                text={t('homePage.createNewQuestion.button')}
                onClick={onCreateNewQuestionClick}
                position="center"
                iconName="write"
              />
            </AppGroup>
          </div>
        </div>
        <AppDivider size="xlarge" />
        <UsersList />
        <AppDivider />
        <QuestionsList
          questionsData={topQuestions.data}
          title={t('homePage.topQuestions.header')}
          isLoading={topQuestions.isLoading}
          isFetching={topQuestions.isFetching}
          pageSize={READ_TOP_QUESTIONS_MAX}
        />
      </>
    )
  }, [
    stats.data,
    topQuestions.data,
    topQuestions.isLoading,
    topQuestions.isFetching,
    t,
    authUser,
    navigateTo,
    setBrowseQuestionsPageState,
  ])
}

export default HomePage
