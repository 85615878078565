import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QUESTION_MAX_NUM_OF_CATEGORIES } from 'atw-shared/utils'
import { AppFormAutoComplete } from 'client/ui-building-blocks'
import { useGetQuestionCategories } from 'client/api'

type Props = {
  isLoading: boolean
}

const CategoriesAutocomplete = ({ isLoading }: Props) => {
  const { t, i18n } = useTranslation()

  const {
    control,
    formState: { errors },
    setError,
  } = useFormContext()

  const { isFetching: isFetchingCategories, data: categories } = useGetQuestionCategories(
    {
      onError: err => {
        if (err?.msg) {
          setError('categories', {
            message: err.msg.text,
          })
        }
      },
    }
  )

  return (
    <Controller
      control={control}
      name="categories"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormAutoComplete
            value={value}
            options={categories ? categories[i18n.language.toLowerCase()] : []}
            label={t('createQuestionPage.fields.categories.label')}
            placeholder={t('createQuestionPage.fields.categories.placeholder')}
            disabled={isLoading || isFetchingCategories}
            onChange={(e, data) => {
              if ((data.value as string[]).length > QUESTION_MAX_NUM_OF_CATEGORIES) {
                return
              }
              onChange({ target: { value: data.value } })
            }}
            multiple
            required
            error={String(errors.categories?.message || '')}
          />
        )
      }}
    />
  )
}

export default CategoriesAutocomplete
