import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import { AppFormInput } from 'client/ui-building-blocks'
import { PASSWORD_MAX_LENGTH } from 'atw-shared/utils'

type Props = {
  isLoading: boolean
}

const PasswordInput = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name="password"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('common.password.password')}
            type="password"
            autoComplete="one-time-code"
            placeholder={errors.password?.message as string}
            maxLength={PASSWORD_MAX_LENGTH}
            disabled={isLoading}
            error={String(errors.password?.message || '')}
          />
        )
      }}
    />
  )
}

export default PasswordInput
