import { useTranslation } from 'react-i18next'

import { APP_EMAIL, Theme } from 'client/utils'
import { getAssetUrl } from 'client/services'
import { usePage, useEnvContext } from 'client/hooks'

import {
  AppButton,
  AppParagraph,
  AppHeader,
  AppSectionLayout,
  AppImage,
} from 'client/ui-building-blocks'

import styles from './footer.module.scss'

const Footer = () => {
  const { t } = useTranslation()
  const { navigateTo } = usePage()
  const { donationPaymentLink } = useEnvContext()

  const onAboutMoreClick = () => {
    navigateTo({ path: '/about' })
  }

  const onDonationMoreClick = () => {
    window.open(donationPaymentLink, '_blank')
  }

  const html = document.querySelector('html')

  return (
    <div data-testid="footer" className={styles.footer}>
      <AppSectionLayout border inverted={html.dataset.theme === Theme.light}>
        <div>
          <AppHeader
            text={t('rootPage.footer.section1.header')}
            centered
            color="light"
            size="small"
            decoration="underlined"
            marginBottom="xsmall"
          />
          <AppParagraph
            text={t('rootPage.footer.section1.content')}
            color="light"
            size="small"
            marginBottom="small"
          />
          <AppButton
            variant="dark"
            text={t('common.buttons.more')}
            position="center"
            size="small"
            onClick={onAboutMoreClick}
          />
        </div>
        <div>
          <AppHeader
            text={t('rootPage.footer.section2.header')}
            centered
            decoration="underlined"
            color="light"
            size="small"
            marginBottom="xsmall"
          />
          <AppParagraph
            text={t('rootPage.footer.section2.content')}
            color="light"
            size="small"
            marginBottom="small"
          />
          <AppButton
            text={APP_EMAIL}
            position="center"
            size="small"
            href={'mailto:' + APP_EMAIL}
          />
        </div>
        <div>
          <AppHeader
            text={t('rootPage.footer.section3.header')}
            centered
            decoration="underlined"
            color="light"
            size="small"
            marginBottom="xsmall"
          />
          <AppParagraph
            text={t('rootPage.footer.section3.content')}
            color="light"
            size="small"
            marginBottom="small"
          />
          <AppImage
            src={getAssetUrl('stripe-logo.png')}
            width="225px"
            height="58px"
            alt="stripe-logo"
            onClick={onDonationMoreClick}
            centered
          />
        </div>
      </AppSectionLayout>
    </div>
  )
}

export default Footer
