import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'

import { AppButton, AppForm, AppGroup, AppSegment } from 'client/ui-building-blocks'
import { displaySnackBar, parseApiErrors } from 'client/services'
import { useUpdatePassword } from 'client/api'
import { UpdatePasswordFormValues } from 'client/utils'

import CurrentPasswordInput from './fields/current-password-input'
import NewPasswordInput from './fields/new-password-input'

const UpdatePasswordForm = () => {
  const { t } = useTranslation()

  const [apiErrors, setApiErrors] = useState(null)

  const { mutateAsync: updatePassword, isLoading: isUpdatingPassword } =
    useUpdatePassword()

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    setError,
  } = form

  const onSubmit = handleSubmit(data => {
    return updatePassword(data).then(
      data => {
        reset()
        if (data?.msg) {
          displaySnackBar(data.msg)
        }
      },
      err => {
        if (err?.valErr) {
          setApiErrors(parseApiErrors(err?.valErr))
        }
      }
    )
  })

  const onClearBtnClick = () => {
    if (!isUpdatingPassword) {
      reset()
      setApiErrors(null)
    }
  }

  useEffect(() => {
    if (apiErrors) {
      Object.keys(apiErrors).forEach((key: keyof UpdatePasswordFormValues) => {
        setError(key, {
          message: t(`apiValidationErrors.${apiErrors[key]}`),
        })
      })
    }
  }, [t, apiErrors, setError])

  return (
    <FormProvider {...form}>
      <AppSegment centered maxWidth="small">
        <AppForm onSubmit={onSubmit}>
          <CurrentPasswordInput isLoading={isUpdatingPassword} />
          <NewPasswordInput isLoading={isUpdatingPassword} />
          <AppGroup fullWidth alignment="verticalMobile">
            <AppButton
              text={t('common.buttons.save')}
              type="submit"
              disabled={!isDirty}
              loading={isUpdatingPassword}
            />
            <AppButton
              variant="outlined"
              text={t('common.buttons.clear')}
              disabled={!isDirty || isUpdatingPassword}
              onClick={e => {
                e.preventDefault()
                onClearBtnClick()
              }}
            />
          </AppGroup>
        </AppForm>
      </AppSegment>
    </FormProvider>
  )
}

export default UpdatePasswordForm
