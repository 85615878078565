import { AVATAR_SIZE } from 'client/utils'

import createImage from './createImg'

export default async (
  imageSrc: string,
  pixelCrop: { width: number; height: number; x: number; y: number },
  rotation = 0
) => {
  const image = await createImage(imageSrc)
  if (image instanceof ErrorEvent) {
    throw image
  }
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const maxSize = Math.max(Number(image.width), Number(image.height))
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
  canvas.width = safeArea
  canvas.height = safeArea
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate((rotation * Math.PI) / 180)
  ctx.translate(-safeArea / 2, -safeArea / 2)
  ctx.drawImage(
    image,
    safeArea / 2 - Number(image.width) * 0.5,
    safeArea / 2 - Number(image.height) * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  ctx.putImageData(
    data,
    0 - safeArea / 2 + Number(image.width) * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + Number(image.height) * 0.5 - pixelCrop.y
  )

  const image2 = await createImage(canvas.toDataURL('image/png'))
  const canvas2 = document.createElement('canvas')
  const ctx2 = canvas2.getContext('2d')
  canvas2.width = AVATAR_SIZE
  canvas2.height = AVATAR_SIZE
  ctx2.drawImage(image2 as HTMLImageElement, 0, 0, AVATAR_SIZE, AVATAR_SIZE)
  const data2 = ctx2.getImageData(0, 0, AVATAR_SIZE, AVATAR_SIZE)
  ctx2.putImageData(data2, 0, 0)
  return canvas2.toDataURL('image/png')
}
