// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tvbF6D8ur6FmybEH4WoP{min-height:80px;display:flex;justify-content:center}.tvbF6D8ur6FmybEH4WoP>*{transform:scale(1.25)}`, "",{"version":3,"sources":["webpack://./src/client/pages/AuthPage/RegisterForm/register-form.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,sBAAA,CAGF,wBACE,qBAAA","sourcesContent":[".recaptchaContainer {\n  min-height: 80px;\n  display: flex;\n  justify-content: center;\n}\n\n.recaptchaContainer > * {\n  transform: scale(1.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recaptchaContainer": `tvbF6D8ur6FmybEH4WoP`
};
export default ___CSS_LOADER_EXPORT___;
