import { useEffect, useMemo } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { Filter } from 'atw-shared/utils'
import { DEFAULT_LANG, DEFAULT_THEME, MIN_REQ_TIME_MS } from 'client/utils'
import { useAuthenticateUser } from 'client/api'
import { AppLoader, AppDimmer } from 'client/ui-building-blocks'
import { NoAccessModal } from 'client/components'
import { useAppContext, useModalDialogContext, usePage } from 'client/hooks'

import {
  AuthPage,
  ProfilePage,
  SettingsPage,
  QuestionPage,
  HomePage,
  BrowseQuestionsPage,
  RandomQuestionPage,
  CreateQuestionPage,
  AboutPage,
} from 'client/pages'

import {
  displaySnackBar,
  getLocalAuthUser,
  getLocalLastUserConfig,
  startUserSocket,
  stopQuestionSocket,
  killRecoverEmailSocket,
} from 'client/services'

import TopBar from './TopBar/top-bar'
import Footer from './Footer/footer'
import AuthInfo from './AuthInfo/auth-info'
import AccountActivationMsg from './AccountActivationMsg/account-activation-msg'
import PageContainer from './PageContainer/page-container'

import styles from './root-page.module.scss'

const RootPage = () => {
  const { t, i18n } = useTranslation()
  const { pathname, search } = useLocation()
  const { navigateTo, checkQueryParams, PATHS } = usePage()

  const {
    isAppLoading,
    setIsAppLoading,
    recoverPassToken,
    setBrowseQuestionsPageState,
    setAuthUser,
    authUser,
    setListActiveQuestionId,
  } = useAppContext()

  const { showQuestionModal, toggleQuestionModal } = useModalDialogContext()

  const { mutateAsync: authenticate } = useAuthenticateUser({
    authType: 'TOKEN',
    onSuccess: data => {
      const lastUserConfig = getLocalLastUserConfig()
      const html = document.querySelector('html')
      html.setAttribute('data-theme', lastUserConfig?.theme || DEFAULT_THEME)
      i18n.changeLanguage(lastUserConfig?.lang || DEFAULT_LANG)

      if (data.user.config.confirmed) {
        setBrowseQuestionsPageState(state => ({ ...state, tabKey: Filter.NotAnswered }))
      }

      setAuthUser(data.user)

      if (['/', '/auth'].includes(pathname) || !PATHS.includes(pathname)) {
        navigateTo({ path: '/home' })
      }
    },
    onError: err => {
      navigateTo({ path: '/auth' })
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  useEffect(() => {
    const token = getLocalAuthUser().token

    if (!token) {
      const lastUserConfig = getLocalLastUserConfig()
      i18n.changeLanguage(lastUserConfig?.lang || DEFAULT_LANG)

      if (
        ['/', '/profile', '/settings'].includes(pathname) ||
        !PATHS.includes(pathname)
      ) {
        setTimeout(() => {
          setIsAppLoading(false)
          navigateTo({ path: '/home' })
        }, MIN_REQ_TIME_MS)
      } else {
        setTimeout(() => {
          setIsAppLoading(false)
        }, MIN_REQ_TIME_MS)
      }
    } else {
      authenticate({
        token,
      }).then(
        data => {
          startUserSocket(data.user._id.toString())
          setIsAppLoading(false)
        },
        () => {
          setIsAppLoading(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!recoverPassToken) {
      killRecoverEmailSocket()
    }
  }, [recoverPassToken])

  useEffect(() => {
    if (!['/home', '/browse', '/question'].includes(pathname) || !showQuestionModal) {
      stopQuestionSocket()
    }
  }, [pathname, showQuestionModal])

  useEffect(() => {
    const valid = checkQueryParams(pathname, search)
    if (valid) {
      const { _id, questionId } = qs.parse(search.substring(1))
      if (['/home', '/browse'].includes(pathname)) {
        if (questionId) {
          setListActiveQuestionId(questionId as string)
        } else {
          setListActiveQuestionId(null)
        }
      } else if (pathname === '/create') {
        toggleQuestionModal(Boolean(_id))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return useMemo(() => {
    if (isAppLoading) {
      return <AppLoader absolute />
    }

    if (['/', '/auth'].includes(pathname) && authUser) {
      navigateTo({ path: '/home', replace: true })
      return null
    } else if (['/', '/profile', '/settings'].includes(pathname) && !authUser) {
      navigateTo({ path: '/auth', replace: true })
      return null
    }

    return (
      <>
        <AppDimmer />
        <NoAccessModal />
        <div className={styles.rootPage}>
          <div>
            <TopBar />
          </div>
          <div className={styles.mainContent}>
            <AuthInfo />
            <PageContainer>
              <AccountActivationMsg />
              <Routes>
                <Route path="/auth" element={<AuthPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/question" element={<QuestionPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/browse" element={<BrowseQuestionsPage />} />
                <Route path="/random" element={<RandomQuestionPage />} />
                <Route path="/create" element={<CreateQuestionPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/" element={null} />
              </Routes>
            </PageContainer>
          </div>
          {pathname !== '/about' && <Footer />}
        </div>
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isAppLoading, t])
}

export default RootPage
