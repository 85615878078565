import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

import { UserAvatar } from 'client/components'
import { AppContextMenu, AppDivider, AppSegment } from 'client/ui-building-blocks'
import { useGetUser } from 'client/api'
import { usePage, useAppContext, useEnvContext } from 'client/hooks'
import { removeBlob, getUserAvatarUrl, displaySnackBar } from 'client/services'

import Badge from './Badge/badge'
import Stats from './Stats/stats'
import ActivityList from './ActivityList/activity-list'

import styles from './profile-page.module.scss'

const ProfilePage = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const { navigateTo } = usePage()
  const { awsBucketUrl } = useEnvContext()

  const { setSettingsPageState, authUser } = useAppContext()

  const { data: profilePageData, refetch, isFetching, isSuccess, isError } = useGetUser()

  useEffect(() => {
    if (pathname === '/profile') {
      const { _id } = qs.parse(search.substring(1))
      if (_id) {
        removeBlob(getUserAvatarUrl(awsBucketUrl, _id.toString()))
      }
      refetch().then(res => {
        if (res.isError && res.error?.msg) {
          displaySnackBar(res.error.msg)
          setTimeout(() => {
            navigateTo({ path: '/home' })
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  if (!authUser) return null

  const contextMenuItems = []

  if (authUser.config.confirmed) {
    contextMenuItems.push({
      label: t('profilePage.contextMenu.changeAvatar'),
      onClick: () => {
        setSettingsPageState({ tabNo: 4 })
        navigateTo({ path: '/settings' })
      },
    })
  }

  contextMenuItems.push({
    label: t('profilePage.contextMenu.updatePersonalDetails'),
    onClick: () => {
      setSettingsPageState({ tabNo: 2 })
      navigateTo({ path: '/settings' })
    },
  })

  const isProfileUserAuthUser =
    authUser._id.toString() === profilePageData?.data.user._id.toString()

  return (
    <AppSegment
      headerProps={{
        text: t('profilePage.header'),
        iconProps: {
          name: 'user',
        },
        rightSlot: isProfileUserAuthUser ? (
          <AppContextMenu items={contextMenuItems} pointing="top right" />
        ) : null,
      }}
    >
      <>
        {!isError && (
          <div className={styles.grid}>
            <div className={styles.avatarContainer}>
              <UserAvatar
                userId={profilePageData?.data?.user._id.toString()}
                size="large"
              />
            </div>
            <div>
              <Badge
                data={profilePageData?.data}
                isLoading={isFetching || (!isSuccess && !isError)}
              />
              <AppDivider border size="small" />
              <Stats data={profilePageData?.data} />
            </div>
          </div>
        )}
        {isSuccess && <ActivityList />}
      </>
    </AppSegment>
  )
}

export default ProfilePage
