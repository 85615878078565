import { useTranslation } from 'react-i18next'

import { IUser } from 'atw-shared/utils'
import { useGetTopUsers } from 'client/api'
import { UserAvatar } from 'client/components'
import { AppList } from 'client/ui-building-blocks'
import { useAppContext, usePage } from 'client/hooks'

const UsersList = () => {
  const { t } = useTranslation()
  const { navigateTo } = usePage()

  const { authUser } = useAppContext()
  const { data, isLoading, isFetching } = useGetTopUsers()

  const renderItem = (user: IUser) => {
    const _id = user._id.toString()

    const onAvatarClick = () => {
      navigateTo({
        path: '/profile',
        query: { _id },
      })
    }

    return (
      <div key={_id}>
        <UserAvatar
          userId={_id}
          popupContent={user.username}
          onClick={authUser ? onAvatarClick : undefined}
          rounded
        />
      </div>
    )
  }

  return (
    <AppList
      data={data}
      renderItem={renderItem}
      title={t('homePage.topUsers.header')}
      isLoading={isLoading || isFetching}
      isFetching={isFetching}
    />
  )
}

export default UsersList
