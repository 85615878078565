import { AVATAR_SIZE } from 'client/utils'

import { ObjectFit } from './types'

export default {
  image: '',
  crop: { x: 10, y: 10 },
  cropSize: { width: AVATAR_SIZE, height: AVATAR_SIZE, x: 0, y: 0 },
  zoom: 1,
  minZoom: 1,
  maxZoom: 6,
  rotation: 0,
  zoomWithScroll: false,
  aspect: 1,
  showGrid: false,
  objectFit: 'cover' as ObjectFit,
}
