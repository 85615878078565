import { useTranslation } from 'react-i18next'
import { displaySnackBar } from 'client/services'
import { useGetDeactivationLink } from 'client/api'
import { useModalDialogContext } from 'client/hooks'

import {
  AppButton,
  AppGroup,
  AppHeader,
  AppModalDialog,
  AppParagraph,
} from 'client/ui-building-blocks'

type Props = {
  onClose: () => void
}

const DeleteAccountModal = ({ onClose }: Props) => {
  const { t } = useTranslation()

  const { showDeleteAccountModal } = useModalDialogContext()

  const { isFetching, refetch: getDeactivationLink } = useGetDeactivationLink({
    onSuccess: data => {
      onClose()
      if (data?.msg) {
        displaySnackBar(data.msg)
      }
    },
    onError: err => {
      onClose()
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  const onProceedClick = () => {
    getDeactivationLink()
  }

  return (
    <AppModalDialog
      open={showDeleteAccountModal}
      onClose={onClose}
      Header={
        <AppHeader
          text={t('app.modals.deleteAccount.header')}
          iconProps={{ name: 'warning' }}
          dividing
        />
      }
      Content={<AppParagraph text={t('app.modals.deleteAccount.content')} />}
      Actions={
        <AppGroup centered>
          <AppButton
            variant="warning"
            size="small"
            text={t('common.buttons.proceed')}
            loading={isFetching}
            onClick={onProceedClick}
          />
          <AppButton
            variant="outlined"
            size="small"
            text={t('common.buttons.cancel')}
            disabled={isFetching}
            onClick={onClose}
          />
        </AppGroup>
      }
    />
  )
}

export default DeleteAccountModal
