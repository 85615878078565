import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Filter, READ_QUESTIONS_MAX } from 'atw-shared/utils'
import { QuestionsList } from 'client/components'
import { useGetQuestions } from 'client/api'
import { BrowseQuestionsFormValues, DEFAULT_THEME } from 'client/utils'
import { useAppContext, useModalDialogContext } from 'client/hooks'

import {
  AppFluidMenu,
  IconName,
  AppMessageBox,
  AppDivider,
  AppSegment,
  AppImage,
} from 'client/ui-building-blocks'

import {
  displaySnackBar,
  getLocalLastUserConfig,
  isAccountFull,
  getAccountStatus,
  getAssetUrl,
} from 'client/services'

import SearchForm from './SearchForm/search-form'

export const getBrowseQuestionsFormConfig = (t: TFunction) =>
  [
    {
      filter: Filter.NotAnswered,
      label: t('browseQuestionsPage.fields.filter', { returnObjects: true })[
        Filter.NotAnswered
      ],
      icon: 'waiting',
    },
    {
      filter: Filter.Answered,
      label: t('browseQuestionsPage.fields.filter', { returnObjects: true })[
        Filter.Answered
      ],
      icon: 'checked',
    },
    {
      filter: Filter.Created,
      label: t('browseQuestionsPage.fields.filter', { returnObjects: true })[
        Filter.Created
      ],
      icon: 'user',
    },
    {
      filter: Filter.Followed,
      label: t('browseQuestionsPage.fields.filter', { returnObjects: true })[
        Filter.Followed
      ],
      icon: 'follow',
    },
    {
      filter: Filter.Terminated,
      label: t('browseQuestionsPage.fields.filter', { returnObjects: true })[
        Filter.Terminated
      ],
      icon: 'terminated',
    },
    {
      filter: Filter.All,
      label: '',
      icon: null,
    },
  ] as { filter: Filter; label: string; icon: IconName }[]

const BrowseQuestionsPage = () => {
  const { t } = useTranslation()

  const { setBrowseQuestionsPageState, browseQuestionsPageState, authUser } =
    useAppContext()

  const { tabKey, user, categories, search, pageNo } = browseQuestionsPageState

  const accountStatus = getAccountStatus(authUser)

  const { showQuestionModal } = useModalDialogContext()

  const form = useForm<BrowseQuestionsFormValues>({
    mode: 'onChange',
    defaultValues: {
      categories: [],
      search: '',
    },
  })

  useEffect(() => {
    form.reset({ categories, search }, { keepDefaultValues: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, refetch, isLoading, isFetching } = useGetQuestions({
    showQuestionModal,
    params: {
      filter: tabKey,
      user,
      categories,
      search,
      pageNo,
    },
    onSuccess: data => {
      form.reset({ categories, search }, { keepDefaultValues: true })
      if (!data?.data?.length) {
        setBrowseQuestionsPageState(state => ({ ...state, pageNo: 1 }))
      }
    },
    onError: err => {
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  const onSubmitClick = data => {
    if (data.search !== search || data.categories.toString() !== categories.toString()) {
      setBrowseQuestionsPageState(state => ({
        ...state,
        categories: data.categories,
        search: data.search,
      }))
    } else {
      refetch()
    }
  }

  const formConfig = useMemo(() => {
    return getBrowseQuestionsFormConfig(t)
  }, [t])

  const currentTheme = getLocalLastUserConfig().theme || DEFAULT_THEME

  const showMenu =
    isAccountFull(accountStatus) &&
    tabKey !== Filter.All &&
    (!user || user._id.toString() === authUser._id.toString())

  const visibleMenuItems = formConfig.filter(({ label }) => label)

  let menuItems = []

  if (showMenu) {
    menuItems = visibleMenuItems.map(({ label, icon }) => ({
      label,
      icon,
      disabled: !showMenu,
      onClick: i => {
        if (!isFetching) {
          setBrowseQuestionsPageState(state => ({
            ...state,
            tabKey: visibleMenuItems[i].filter,
            pageNo: 1,
          }))
          return true
        } else {
          return false
        }
      },
    }))
  }

  return (
    <FormProvider {...form}>
      <AppSegment
        headerProps={{
          text: t('browseQuestionsPage.header'),
          iconProps: {
            name: 'search',
          },
        }}
      >
        <AppMessageBox
          paragraphs={t('browseQuestionsPage.messages', { returnObjects: true })}
          imgNode={
            <AppImage
              src={getAssetUrl(`survey-browse-${currentTheme}.png`)}
              width="90px"
              height="90px"
            />
          }
          size="small"
        />
        <AppDivider />
        <AppFluidMenu
          menuItems={menuItems}
          defaultItemIndex={visibleMenuItems.findIndex(item => item.filter === tabKey)}
        >
          <AppSegment centered maxWidth="medium" marginBottom={false}>
            <SearchForm isLoading={isFetching} onSubmitClick={onSubmitClick} />
          </AppSegment>
        </AppFluidMenu>
      </AppSegment>
      <QuestionsList
        questionsData={data}
        title={t('browseQuestionsPage.list.total', { count: data?.count || 0 })}
        isLoading={isLoading}
        isFetching={isFetching}
        pageSize={READ_QUESTIONS_MAX}
        activePage={pageNo}
      />
    </FormProvider>
  )
}

export default BrowseQuestionsPage
