import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormName } from 'client/utils'
import { getAssetUrl } from 'client/services'
import { AppDivider, AppSegment } from 'client/ui-building-blocks'
import { AppImage } from 'client/ui-building-blocks'

import LoginForm from './LoginForm/login-form'
import RegisterForm from './RegisterForm/register-form'
import RecoverPasswordForm from './RecoverPasswordForm/recover-password-form'

const AuthPage = () => {
  const { t } = useTranslation()

  const [activeForm, setActiveForm] = useState<FormName>(FormName.AuthenticationForm)

  const APP_SEGMENT_CONFIG = useMemo(() => {
    return {
      [FormName.AuthenticationForm]: {
        text: t('authPage.login.header'),
        iconProps: { name: 'login' },
      },
      [FormName.RegistrationForm]: {
        text: t('authPage.register.header'),
        iconProps: { name: 'register' },
      },
      [FormName.RecoverPasswordForm]: {
        text: t('authPage.recover.header'),
        iconProps: { name: 'recover' },
      },
    }
  }, [t])

  return (
    <AppSegment maxWidth="small" centered headerProps={APP_SEGMENT_CONFIG[activeForm]}>
      {activeForm !== FormName.RegistrationForm && (
        <>
          <AppImage src={getAssetUrl('logo.png')} width="175px" alt="logo" centered />
          <AppDivider />
          <AppDivider />
        </>
      )}
      {activeForm == FormName.AuthenticationForm && (
        <LoginForm setActiveForm={setActiveForm} />
      )}
      {activeForm === FormName.RegistrationForm && (
        <RegisterForm setActiveForm={setActiveForm} />
      )}
      {activeForm === FormName.RecoverPasswordForm && (
        <RecoverPasswordForm setActiveForm={setActiveForm} />
      )}
    </AppSegment>
  )
}

export default AuthPage
