import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AppMsgCode } from 'atw-shared/utils'
import { UpdateUserDetailsFormValues } from 'client/utils'
import { displaySnackBar, parseApiErrors } from 'client/services'
import { useUpdateUser } from 'client/api'
import { useAppContext } from 'client/hooks'
import { AppButton, AppForm, AppGroup, AppSegment } from 'client/ui-building-blocks'

import {
  NewEmailInput,
  NewUsernameInput,
  DateOfBirthPicker,
  CountryAutocomplete,
  SexCheckbox,
} from 'client/components'

const UpdateUserDetailsForm = () => {
  const { t } = useTranslation()
  const { authUser, setAuthUser } = useAppContext()

  const { email, username, dateOfBirth, country, sex } = authUser

  const [apiErrors, setApiErrors] = useState(null)

  const form = useForm<UpdateUserDetailsFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email,
      username,
      dateOfBirth,
      country,
      sex,
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = form

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useUpdateUser({
    onSuccess: data => {
      setAuthUser(data.user)

      if (data?.msg?.code === AppMsgCode.UserDetailsUpdated) {
        displaySnackBar(data.msg)
      }

      const { email, username, dateOfBirth, country, sex } = data.user
      reset({ email, username, dateOfBirth, country, sex })
    },
    onError: err => {
      if (err?.valErr) {
        setApiErrors(parseApiErrors(err.valErr))
      }
    },
  })

  const onResetClick = e => {
    e.preventDefault()
    reset()
    setApiErrors(null)
  }

  const onSubmit = handleSubmit(async (data: UpdateUserDetailsFormValues) => {
    return updateUser({
      ...data,
      dateOfBirth: new Date(data.dateOfBirth).toISOString().split('T')[0],
    }).catch(() => null)
  })

  return (
    <FormProvider {...form}>
      <AppSegment centered maxWidth="small">
        <AppForm onSubmit={onSubmit}>
          <NewEmailInput isLoading={isUpdatingUser} apiErrors={apiErrors} />
          <NewUsernameInput isLoading={isUpdatingUser} apiErrors={apiErrors} />
          <DateOfBirthPicker isLoading={isUpdatingUser} />
          <CountryAutocomplete isLoading={isUpdatingUser} />
          <SexCheckbox isLoading={isUpdatingUser} />
          <AppGroup fullWidth alignment="verticalMobile">
            <AppButton
              text={t('common.buttons.save')}
              loading={isUpdatingUser}
              disabled={!isDirty}
              type="submit"
            />
            <AppButton
              variant="outlined"
              text={t('common.buttons.reset')}
              disabled={isUpdatingUser || !isDirty}
              onClick={onResetClick}
            />
          </AppGroup>
        </AppForm>
      </AppSegment>
    </FormProvider>
  )
}

export default UpdateUserDetailsForm
