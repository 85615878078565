import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Filter } from 'atw-shared/utils'
import { useAuthenticateUser } from 'client/api'
import { getLocalLastUserConfig, startUserSocket, displaySnackBar } from 'client/services'
import { useAppContext, usePage } from 'client/hooks'
import { AppButton, AppDivider, AppGroup, AppParagraph } from 'client/ui-building-blocks'

import {
  DEFAULT_LANG,
  DEFAULT_THEME,
  FormName,
  AuthenticationFormValues,
} from 'client/utils'

import UsernameInput from './fields/username-input'
import PasswordInput from './fields/password-input'
import AppForm from 'client/ui-building-blocks/AppForm/app-form'

type Props = {
  setActiveForm: (name: FormName) => void
}

const LoginForm = ({ setActiveForm }: Props) => {
  const { t, i18n } = useTranslation()

  const {
    setBrowseQuestionsPageState,
    setAuthUser,
    redirectQuestionId,
    setRedirectQuestionId,
  } = useAppContext()

  const { navigateTo } = usePage()

  const { mutateAsync: authenticate, isLoading: isAuthenticating } = useAuthenticateUser({
    authType: 'CREDENTIALS',
    onSuccess: data => {
      const lastUserConfig = getLocalLastUserConfig()
      const html = document.querySelector('html')
      html.setAttribute('data-theme', lastUserConfig?.theme || DEFAULT_THEME)
      i18n.changeLanguage(lastUserConfig?.lang || DEFAULT_LANG)

      if (data.user.config.confirmed) {
        setBrowseQuestionsPageState(state => ({ ...state, tabKey: Filter.NotAnswered }))
      }

      setAuthUser(data.user)
      startUserSocket(data.user._id.toString())

      if (redirectQuestionId) {
        navigateTo({ path: '/question', query: { _id: redirectQuestionId } })
        setRedirectQuestionId(null)
      } else {
        navigateTo({ path: '/home' })
      }
    },
    onError: err => {
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  const form = useForm<AuthenticationFormValues>({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = form

  const onClearClick = () => {
    reset()
  }

  const onForgotPasswordClick = () => {
    setActiveForm(FormName.RecoverPasswordForm)
    window.scrollTo(0, 0)
  }

  const onRegisterClick = () => {
    setActiveForm(FormName.RegistrationForm)
    window.scrollTo(0, 0)
  }

  const onSubmit = handleSubmit(async data => {
    return authenticate({
      credentials: data,
    }).catch(() => null)
  })

  return (
    <>
      <FormProvider {...form}>
        <AppForm onSubmit={onSubmit}>
          <UsernameInput isLoading={isAuthenticating} />
          <PasswordInput isLoading={isAuthenticating} />
          <AppGroup fullWidth alignment="verticalMobile">
            <AppButton
              text={t('common.buttons.submit')}
              type="submit"
              loading={isAuthenticating}
              disabled={!isDirty || !isEmpty(errors)}
            />
            <AppButton
              variant="outlined"
              text={t('common.buttons.clear')}
              disabled={isAuthenticating || !isDirty}
              onClick={onClearClick}
            />
          </AppGroup>
        </AppForm>
      </FormProvider>
      <AppDivider border size="large" />
      <AppButton
        variant="outlined"
        text={t('authPage.login.forgotPassword')}
        disabled={isAuthenticating}
        onClick={onForgotPasswordClick}
        position="center"
        size="small"
      />
      <AppDivider size="small" />
      <AppParagraph
        text={t('authPage.login.dontHaveAnAccount')}
        centered
        size="small"
        marginBottom="xsmall"
      />
      <AppButton
        variant="outlined"
        text={t('authPage.login.register')}
        disabled={isAuthenticating}
        onClick={onRegisterClick}
        position="center"
        size="small"
      />
    </>
  )
}

export default LoginForm
