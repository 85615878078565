import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { AppSegment, IconName, AppParagraph } from 'client/ui-building-blocks'

type Section = { icon: IconName; header: string; content: string[] }

const AboutPage = () => {
  const { t } = useTranslation()

  return (
    <div data-testid="about-page">
      <AppSegment maxWidth="large" centered>
        {(t('aboutPage.sections', { returnObjects: true }) as Section[]).map(
          (section: Section, i: number) => {
            return (
              <div key={i}>
                <AppSegment
                  headerProps={{
                    text: section.header,
                    marginBottom: 'small',
                    iconProps: {
                      name: section.icon,
                    },
                  }}
                  marginBottom="medium"
                >
                  {section.content.map((p, i) => (
                    <AppParagraph key={i} text={p} size="small" marginBottom="xsmall" />
                  ))}
                </AppSegment>
              </div>
            )
          }
        )}
      </AppSegment>
    </div>
  )
}

export default memo(AboutPage)
