import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import times from 'lodash/times'

import { MAX_NUM_OF_QUESTION_OPTIONS, QuestionType } from 'atw-shared/utils'
import { AppFormSlider } from 'client/ui-building-blocks'

type Props = {
  step: number
  min: number
  max: number
}

const NumOfOptionsSlider = ({ step, min, max }: Props) => {
  const { t } = useTranslation()

  const { control, watch, setValue, resetField } = useFormContext()

  useEffect(() => {
    const subscription = watch(({ type, numOfOptions }, { name }) => {
      if (name === 'numOfOptions') {
        const fillWithNumbers = type === QuestionType.Rating

        if (fillWithNumbers) {
          times(MAX_NUM_OF_QUESTION_OPTIONS, (i: number) => {
            setValue(
              `options.${i + 1}` as never,
              (i + 1 <= numOfOptions ? i + 1 : '') as never
            )
          })
        } else {
          for (let n = numOfOptions + 1; n <= MAX_NUM_OF_QUESTION_OPTIONS; n++) {
            resetField(`options.${n}` as never)
          }
        }
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      control={control}
      name="numOfOptions"
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormSlider
            value={value}
            onChange={onChange}
            label={`${t('createQuestionPage.fields.numOfOptions')} (${value})`}
            min={min}
            max={max}
            step={step}
          />
        )
      }}
    />
  )
}

export default NumOfOptionsSlider
