import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AppFormInput } from 'client/ui-building-blocks'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from 'atw-shared/utils'
import { hasCredentialAllCharsValid } from 'atw-shared/validation'
import { useAppContext } from 'client/hooks'

type Props = {
  isLoading: boolean
  getRecoveryLinkSuccess: boolean
}

const NewPasswordInput = ({ isLoading, getRecoveryLinkSuccess }: Props) => {
  const { t } = useTranslation()

  const { recoverPassToken } = useAppContext()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const isDisabled = isLoading || !recoverPassToken || !getRecoveryLinkSuccess

  console.log(isDisabled)

  return (
    <Controller
      control={control}
      name="newPassword"
      disabled={isDisabled}
      rules={
        !isDisabled
          ? {
              required: t('common.validation.required'),
              minLength: {
                value: PASSWORD_MIN_LENGTH,
                message: t('common.validation.minLength', { min: PASSWORD_MIN_LENGTH }),
              },
              maxLength: {
                value: PASSWORD_MAX_LENGTH,
                message: t('common.validation.maxLength', { max: PASSWORD_MAX_LENGTH }),
              },
              validate: value =>
                hasCredentialAllCharsValid(value, true) ||
                t('common.validation.invalidChars'),
            }
          : {}
      }
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('common.password.newPassword')}
            type="password"
            placeholder={`${PASSWORD_MIN_LENGTH} - ${PASSWORD_MAX_LENGTH}`}
            maxLength={PASSWORD_MAX_LENGTH}
            disabled={isDisabled}
            error={String(errors?.newPassword?.message || '')}
          />
        )
      }}
    />
  )
}

export default NewPasswordInput
