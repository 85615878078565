import { useTranslation } from 'react-i18next'
import keys from 'lodash/keys'

import { ACTIVITY_LIST_MAX_ITEMS, IActivity } from 'atw-shared/utils'
import { useGetUserActivity } from 'client/api'
import { humanizeFromToNow, getQuestionUrl } from 'client/services'

import {
  AppHeader,
  AppIcon,
  AppList,
  AppParagraph,
  IconName,
} from 'client/ui-building-blocks'

import styles from './activity-list.module.scss'

const CONFIG = {
  createdAt: 'write',
  answeredAt: 'checked',
  terminatedAt: 'terminated',
  reportedAt: 'bug',
} as Record<string, IconName>

const ActivityList = () => {
  const { t, i18n } = useTranslation()

  const { data: activityData, isLoading, isFetching } = useGetUserActivity()

  const renderActivityItem = (item: IActivity, i: number) => {
    const onQuestionLinkClick = () => {
      window.open(getQuestionUrl(item._id.toString()), '_blank')
    }

    const fieldNames = keys(CONFIG)
    const fieldName = keys(item).find(k => fieldNames.includes(k) && item[k])

    const actionText =
      t('profilePage.activity', { returnObjects: true })[fieldName] +
      ' ' +
      humanizeFromToNow(
        item.createdAt || item.answeredAt || item.terminatedAt || item.reportedAt,
        i18n.language
      )

    return (
      <div key={i} className={styles.activityItem} onClick={onQuestionLinkClick}>
        <div className={styles.iconContainer}>
          <AppIcon name={CONFIG[fieldName]} size="small" />
        </div>
        <div className={styles.textContent}>
          <div className={styles.linkColumn}>
            <AppHeader text={item.text} />
          </div>
          <div className={styles.actionColumn}>
            <AppParagraph text={actionText} color="secondary" size="small" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <AppList
      data={{ count: activityData?.data.length, data: activityData?.data }}
      renderItem={renderActivityItem}
      title={t('profilePage.activity.title')}
      isLoading={isLoading}
      isFetching={isFetching}
      pageSize={ACTIVITY_LIST_MAX_ITEMS}
      gridLayoutVariant="list"
    />
  )
}

export default ActivityList
