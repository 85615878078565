import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CreateQuestionFormValues } from 'client/utils'
import { AppFormSlider } from 'client/ui-building-blocks'

type Props = {
  min: number
  max: number
  disabled: boolean
}

const NumOfSelectableOptionsSlider = ({ min, max, disabled }: Props) => {
  const { t } = useTranslation()

  const { control, watch, setValue } = useFormContext<CreateQuestionFormValues>()

  useEffect(() => {
    const subscription = watch(({ numOfOptions, numOfSelectableOptions }, { name }) => {
      if (name === 'numOfOptions') {
        if (numOfSelectableOptions >= numOfOptions) {
          setValue('numOfSelectableOptions', numOfOptions - 1)
        }
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      control={control}
      name="numOfSelectableOptions"
      render={({ field: { value, onChange } }) => {
        if (disabled) {
          return null
        }
        return (
          <AppFormSlider
            value={value}
            onChange={onChange}
            label={`${t('createQuestionPage.fields.numOfSelectableOptions')} (${value})`}
            min={min}
            max={max}
            step={1}
          />
        )
      }}
    />
  )
}

export default NumOfSelectableOptionsSlider
