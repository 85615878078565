import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'

import { MAX_NUM_OF_QUESTION_OPTIONS, QuestionType } from 'atw-shared/utils'
import { CreateQuestionFormValues } from 'client/utils'
import { AppButton, AppDivider, AppForm, AppGroup } from 'client/ui-building-blocks'
import { fixScrollPosition } from 'client/helpers'

import TypeSelect from './fields/type-select'
import TextInput from './fields/text-input'
import CategoriesAutocomplete from './fields/categories-autocomplete'
import CanBeReansweredCheckbox from './fields/can-be-reanswered-checkbox'
import NumOfOptionsSlider from './fields/num-of-options-slider'
import NumOfSelectableOptionsSlider from './fields/num-of-selectable-options-slider'
import RangeOfSelectableOptionsSlider from './fields/range-of-selectable-options-slider'
import OptionInputs from './fields/option-inputs'

import NEW_QUESTION_EXAMPLES from '../new-question-examples'

import type { Screen } from '../create-question-page'

type Props = {
  isCreatingQuestion: boolean
  activeScreen: Screen
  setActiveScreen: (screen: Screen) => void
}

const NewQuestionForm = ({
  isCreatingQuestion,
  activeScreen,
  setActiveScreen,
}: Props) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, errors },
  } = useFormContext<CreateQuestionFormValues>()

  const { type, numOfOptions } = getValues()

  const onExampleClick = () => {
    reset(NEW_QUESTION_EXAMPLES[getValues().type], {
      keepDefaultValues: true,
    })
  }

  const onResetBtnClick = e => {
    e.preventDefault()
    reset()
  }

  const onNextClick = () => {
    handleSubmit(() => {
      if (type !== QuestionType.SingleChoiceYesOrNo) {
        setActiveScreen('FORM_2')
      } else {
        setActiveScreen('PREVIEW')
      }
      fixScrollPosition(false)
    })()
  }

  const onPreviewClick = () => {
    handleSubmit(() => {
      setActiveScreen('PREVIEW')
      fixScrollPosition(false)
    })()
  }

  const onBackClick = () => {
    setActiveScreen('FORM_1')
    fixScrollPosition(false)
  }

  return (
    <>
      {activeScreen === 'FORM_1' && (
        <>
          <AppForm>
            <TypeSelect />
            <AppButton
              variant="outlined"
              text={t('createQuestionPage.exampleBtn')}
              size="small"
              position="center"
              onClick={onExampleClick}
              disabled={isCreatingQuestion}
            />
            <CategoriesAutocomplete isLoading={isCreatingQuestion} />
            <TextInput isLoading={isCreatingQuestion} />
            <div>
              <AppDivider size="small" />
              <CanBeReansweredCheckbox />
              <AppDivider size="small" />
            </div>
            <AppGroup fullWidth>
              <AppButton
                variant="outlined"
                text={t('common.buttons.reset')}
                onClick={onResetBtnClick}
                disabled={isCreatingQuestion || !(isDirty || !isEmpty(errors))}
              />
              <AppButton
                variant="outlined"
                iconName="next"
                iconPosition="right"
                text={t('common.buttons.next')}
                textPosition="right"
                position="center"
                onClick={onNextClick}
              />
            </AppGroup>
          </AppForm>
        </>
      )}
      {activeScreen === 'FORM_2' && (
        <AppForm>
          {type === QuestionType.SingleChoice && (
            <NumOfOptionsSlider step={1} min={2} max={MAX_NUM_OF_QUESTION_OPTIONS} />
          )}
          {type === QuestionType.MultiChoiceExact && (
            <NumOfOptionsSlider step={1} min={3} max={MAX_NUM_OF_QUESTION_OPTIONS} />
          )}
          {type === QuestionType.MultiChoiceRange && (
            <NumOfOptionsSlider step={1} min={2} max={MAX_NUM_OF_QUESTION_OPTIONS} />
          )}
          {type === QuestionType.Rating && (
            <NumOfOptionsSlider step={5} min={5} max={10} />
          )}
          {type === QuestionType.MultiChoiceExact && (
            <NumOfSelectableOptionsSlider
              min={2}
              max={numOfOptions - 1}
              disabled={numOfOptions === 3}
            />
          )}
          {type === QuestionType.MultiChoiceRange && (
            <RangeOfSelectableOptionsSlider
              min={1}
              max={numOfOptions}
              disabled={numOfOptions === 2}
            />
          )}
          {type !== QuestionType.Rating && (
            <>
              <AppDivider size="medium" />
              <OptionInputs isLoading={isCreatingQuestion} defaultValue={2} />
            </>
          )}
          <AppGroup fullWidth>
            <AppButton
              variant="outlined"
              text={t('common.buttons.back')}
              textPosition="left"
              iconName="prev"
              fullWidth
              onClick={onBackClick}
            />
            <AppButton
              variant="outlined"
              text={t('common.buttons.preview')}
              textPosition="right"
              onClick={onPreviewClick}
              iconName="next"
              iconPosition="right"
              disabled={isCreatingQuestion}
            />
          </AppGroup>
        </AppForm>
      )}
    </>
  )
}

export default NewQuestionForm
