import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Menu, Dropdown } from 'client/semantic-imports'
import { AccountStatus, Filter } from 'atw-shared/utils'
import { useAppContext, useModalDialogContext, usePage } from 'client/hooks'
import { AppHeader } from 'client/ui-building-blocks'

import {
  removeLocalAuthUser,
  killUserSocket,
  killQuestionSocket,
  getAccountStatus,
} from 'client/services'

import styles from './top-bar.module.scss'
import './top-bar.scss'

const TopBar = () => {
  const { pathname, search } = useLocation()
  const { t } = useTranslation()
  const { navigateTo } = usePage()

  const {
    authUser,
    setAuthUser,
    setRedirectQuestionId,
    setSettingsPageState,
    setBrowseQuestionsPageState,
  } = useAppContext()

  const accountStatus = getAccountStatus(authUser)

  const { closeAllModals } = useModalDialogContext()

  const getOnClickHandler = (path: string) => {
    if (path === '/auth' && pathname === '/question') {
      setRedirectQuestionId(search.split('=')[1])
    }
    return () => {
      navigateTo({
        path,
        query: path === '/profile' ? { _id: authUser._id.toString() } : null,
      })
    }
  }

  const onLogoutClick = () => {
    killUserSocket()
    killQuestionSocket()
    removeLocalAuthUser()
    setSettingsPageState({ tabNo: 1 })
    setBrowseQuestionsPageState(state => ({
      ...state,
      tabKey: Filter.All,
      user: null,
      categories: [],
      search: '',
      pageNo: 1,
    }))
    setAuthUser(null)
    closeAllModals()
    navigateTo({ path: '/auth' })
  }

  return (
    <div className={classNames('appTopBar', styles.topBarContainer)}>
      <Menu inverted borderless fluid className={styles.topBar}>
        <Menu.Item link as="h4" onClick={getOnClickHandler('/home')}>
          <div className={styles.appName}>
            <AppHeader text={t('rootPage.topBar.appName')} color="highlighted" />
          </div>
          <div className={styles.appNameShort}>
            <AppHeader text={t('rootPage.topBar.appNameShort')} color="highlighted" />
          </div>
        </Menu.Item>
        <Menu.Menu>
          <Dropdown
            simple
            item
            aria-label="browse"
            trigger={
              <AppHeader
                text={t('rootPage.topBar.browse.label')}
                color="light"
                size="small"
              />
            }
          >
            <Dropdown.Menu className={styles.menu}>
              <Dropdown.Item onClick={getOnClickHandler('/browse')}>
                <AppHeader
                  text={t('rootPage.topBar.browse.items.all')}
                  color="light"
                  size="small"
                />
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.item}
                onClick={getOnClickHandler('/random')}
              >
                <AppHeader
                  text={t('rootPage.topBar.browse.items.random')}
                  color="light"
                  size="small"
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
        <Menu.Item onClick={getOnClickHandler('/create')}>
          <AppHeader text={t('rootPage.topBar.create')} color="light" size="small" />
        </Menu.Item>
        <Menu.Menu position="right">
          <Dropdown
            simple
            item
            aria-label="account"
            trigger={
              <AppHeader
                text={t('rootPage.topBar.menu.label')}
                color="light"
                size="small"
              />
            }
          >
            <Dropdown.Menu className={styles.menu}>
              {accountStatus !== AccountStatus.UNKNOWN && (
                <>
                  <Dropdown.Item onClick={getOnClickHandler('/profile')}>
                    <AppHeader
                      text={t('rootPage.topBar.menu.items.profile')}
                      color="light"
                      size="small"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={getOnClickHandler('/settings')}>
                    <AppHeader
                      text={t('rootPage.topBar.menu.items.settings')}
                      color="light"
                      size="small"
                    />
                  </Dropdown.Item>
                </>
              )}
              {accountStatus !== AccountStatus.UNKNOWN ? (
                <Dropdown.Item onClick={onLogoutClick}>
                  <AppHeader
                    text={t('rootPage.topBar.menu.items.logout')}
                    color="light"
                    size="small"
                  />
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={getOnClickHandler('/auth')}>
                  <AppHeader
                    text={t('rootPage.topBar.menu.items.login')}
                    color="light"
                    size="small"
                  />
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </div>
  )
}

export default TopBar
