import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AppFormSlider } from 'client/ui-building-blocks'

type Props = {
  min: number
  max: number
  disabled: boolean
}

const RangeOfSelectableOptionsSlider = ({ min, max, disabled }: Props) => {
  const { t } = useTranslation()

  const { control, watch, setValue } = useFormContext()

  useEffect(() => {
    const subscription = watch(({ numOfOptions, rangeOfSelectableOptions }, { name }) => {
      if (name === 'numOfOptions') {
        if (rangeOfSelectableOptions[1] > numOfOptions) {
          setValue('rangeOfSelectableOptions', [
            rangeOfSelectableOptions[0] >= numOfOptions
              ? numOfOptions - 1
              : rangeOfSelectableOptions[0],
            numOfOptions,
          ])
        }
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      control={control}
      name="rangeOfSelectableOptions"
      render={({ field: { value, onChange } }) => {
        if (disabled) {
          return null
        }
        return (
          <AppFormSlider
            rangeMode
            value={value}
            onChange={onChange}
            label={`${t('createQuestionPage.fields.numOfSelectableOptions')} (${
              value[0]
            } - ${value[1]})`}
            min={min}
            max={max}
            step={1}
          />
        )
      }}
    />
  )
}

export default RangeOfSelectableOptionsSlider
