import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import {
  AppButton,
  AppDivider,
  AppFormCheckBox,
  AppMessageBox,
  AppSegment,
} from 'client/ui-building-blocks'

import { Form } from 'client/semantic-imports'
import { useModalDialogContext } from 'client/hooks'

import DeleteAccountModal from './DeleteAccountModal/delete-account-modal'

const DeactivateAccounForm = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { toggleDeleteAccountModal } = useModalDialogContext()

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      deactivate: [],
    },
  })

  const onSubmit = handleSubmit(() => {
    toggleDeleteAccountModal(true)
  })

  const onClose = () => {
    if (!queryClient.isFetching() && !queryClient.isMutating()) {
      toggleDeleteAccountModal(false)
      reset()
    }
  }

  return (
    <>
      <DeleteAccountModal onClose={onClose} />
      <AppSegment centered maxWidth="medium">
        <AppMessageBox paragraphs={[t('settingsPage.deactivation.info')]} />
        <AppDivider size="large" />
        <AppSegment maxWidth="small" centered>
          <Form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="deactivate"
              rules={{
                required: true,
              }}
              render={({ field: { value: deactivate, onChange } }) => {
                return (
                  <AppFormCheckBox
                    currentValues={deactivate}
                    options={[{ value: 0, label: t('settingsPage.deactivation.label') }]}
                    onChange={onChange}
                  />
                )
              }}
            />
            <AppDivider size="medium" />
            <AppButton
              variant="warning"
              type="submit"
              text={t('common.buttons.deactivate')}
              disabled={!isDirty || Boolean(errors?.deactivate)}
              position="center"
            />
          </Form>
        </AppSegment>
      </AppSegment>
    </>
  )
}

export default DeactivateAccounForm
