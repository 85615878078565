export { default as AboutPage } from './AboutPage/about-page'
export { default as AuthPage } from './AuthPage/auth-page'
export { default as BrowseQuestionsPage } from './BrowseQuestionsPage/browse-questions-page'
export { default as CreateQuestionPage } from './CreateQuestionPage/create-question-page'
export { default as HomePage } from './HomePage/home-page'
export { default as ProfilePage } from './ProfilePage/profile-page'
export { default as QuestionPage } from './QuestionPage/question-page'
export { default as RandomQuestionPage } from './RandomQuestionPage/random-question-page'
export { default as RootPage } from './RootPage/root-page'
export { default as SettingsPage } from './SettingsPage/settings-page'
