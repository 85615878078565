import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import values from 'lodash/values'

import { IQuestion, QuestionType } from 'atw-shared/utils'
import { AppButton, AppDivider, AppGroup } from 'client/ui-building-blocks'
import { useAppContext } from 'client/hooks'
import { fixScrollPosition } from 'client/helpers'

import TitleBox from 'client/components/Question/TitleBox/title-box'
import SubmitForm from 'client/components/Question/SubmitForm/submit-form'

import { Screen } from '../create-question-page'

type Props = {
  isCreatingQuestion: boolean
  setActiveScreen: (screen: Screen) => void
  onSubmit: () => void
}

const QuestionPreview = ({ isCreatingQuestion, setActiveScreen, onSubmit }: Props) => {
  const { t } = useTranslation()

  const { authUser } = useAppContext()
  const { getValues } = useFormContext()

  const {
    type,
    categories,
    text,
    options,
    numOfSelectableOptions,
    rangeOfSelectableOptions,
    canBeReanswered,
  } = getValues()

  const [min, max] = rangeOfSelectableOptions

  const question = {
    type,
    creatorId: authUser?._id,
    createdAt: Date.now(),
    categories,
    text,
    options: values(options).filter(o => o),
    selectableOptions:
      type === QuestionType.MultiChoiceRange
        ? { range: { min, max } }
        : { exact: numOfSelectableOptions },
    canBeReanswered,
  } as IQuestion

  const onBackClick = () => {
    if (type !== QuestionType.SingleChoiceYesOrNo) {
      setActiveScreen('FORM_2')
    } else {
      setActiveScreen('FORM_1')
    }
    fixScrollPosition(false)
  }

  const onCreateClick = () => {
    return onSubmit()
  }

  return (
    <>
      <TitleBox question={question} questionsData={{ count: 1, data: [question] }} />
      <AppDivider size="large" />
      <SubmitForm
        question={question}
        isFetchingQuestion={false}
        isCreatingAnswer={false}
        isUpdatingAnswer={false}
        isInReanswerMode={false}
        setIsInReanswerMode={null}
        onSubmit={null}
        isSubmitting={false}
        previewMode
      />
      <AppDivider size="large" />
      <AppGroup fullWidth>
        <AppButton
          variant="outlined"
          text={t('common.buttons.back')}
          textPosition="left"
          iconName="prev"
          onClick={onBackClick}
          position="center"
        />
        <AppButton
          loading={isCreatingQuestion}
          text={t('common.buttons.create')}
          onClick={onCreateClick}
        />
      </AppGroup>
    </>
  )
}

export default QuestionPreview
